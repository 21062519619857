import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  {
    webconfigs {
      show_menu_link
      authorization_type
    }
  }
`;

export const webconfigs = () =>
  gqlClient
    .request(query)
    .then(({ webconfigs: webconfigsData }) => webconfigsData);
