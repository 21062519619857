import { observable, action, computed } from 'mobx';
import { players } from '../api/players';
import { sync } from '../api/sync';
import { team } from '../api/team';

export const SYNC_MODALS = {
  INVITE: 'invite',
  NOT_ENOUGH: 'not-enough',
  EMPTY: 'empty',
  SUCCESS: 'success',
};

/**
 * @typedef TeamRecord
 * @property {string} name
 * @property {number} synchronized_at
 * @property {any} synchronized_players
 */

export class TeamStore {
  initialData = {};

  @observable fetching = false;

  /** @type {TeamRecord} */
  @observable data = this.initialData;

  @observable players = [];

  @observable showSyncModal = false;

  constructor(appStore) {
    this.appStore = appStore;

    this.fetchTeam = this.fetchTeam.bind(this);
    this.fetchPlayers = this.fetchPlayers.bind(this);
  }

  @computed
  get synchronized() {
    return this.data && this.data.synchronized_players
      ? this.data.synchronized_players
      : [];
  }

  @computed
  get hasSynchronized() {
    return this.synchronized.length > 0;
  }

  @computed
  get isUserSynchronized() {
    return Boolean(
      this.synchronized.find(
        item => item.id === this.appStore.userStore.data.id
      )
    );
  }

  @computed
  get isTeamSynchronized() {
    return this.data.is_synchronized;
  }

  async fetchTeam() {
    this.setFetching(true);
    try {
      const data = await team(this.appStore.userStore.data.team.id);
      this.setData(data);
    } catch (error) {
      this.appStore.publishError({
        text: 'Не удалось получить данные о команде, попробуйте ещё раз.',
      });
    } finally {
      this.setFetching(false);
    }
  }

  fetchPlayers() {
    this.setFetching(true);
    return players(this.appStore.userStore.data.team.id)
      .then(data => {
        this.setPlayers(data);
      })
      .finally(() => {
        this.setFetching(false);
      });
  }

  @action
  setData(data) {
    this.data = data;
  }

  @action
  setPlayers(data) {
    this.players = data;
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }

  sync = async () => {
    this.setFetching(true);
    try {
      const data = await sync({
        teamId: this.appStore.userStore.data.team.id,
        player: this.appStore.userStore.data.id,
      });
      this.setData(data);
    } catch (error) {
      this.appStore.publishError({
        text: 'Не удалось провести синхронизацию, попробуйте ещё раз.',
      });
    } finally {
      this.setFetching(false);
    }
  };

  syncSuccess = async () => {
    await this.fetchTeam();
  };

  syncError = async () => {
    const wasSynchronized = this.isUserSynchronized;
    await this.fetchTeam();
    if (wasSynchronized) {
      this.setShowSyncModal(SYNC_MODALS.NOT_ENOUGH);
    }
  };

  syncComplited = async () => {
    const wasSynchronized = this.isUserSynchronized;
    await this.fetchTeam();
    if (wasSynchronized) {
      this.setShowSyncModal(SYNC_MODALS.SUCCESS);
    }
  };

  syncUsers = async () => {
    await this.fetchTeam();
    if (!this.isUserSynchronized && this.synchronized.length === 1) {
      this.setShowSyncModal(SYNC_MODALS.INVITE);
    }
  };

  @action
  setShowSyncModal = value => {
    this.showSyncModal = value;
  };
}
