import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  query Team($id: ID!) {
    team(where: { id: $id }) {
      id
      name
      is_synchronized
      synchronized_at
      synchronized_players {
        id
        name
        img {
          url
        }
      }
    }
  }
`;

export const team = id =>
  gqlClient.request(query, { id }).then(({ team: teamData }) => teamData);
