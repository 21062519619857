import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react-lite';
import { Spinner } from 'react-bootstrap';
import styles from './news-like.module.css';
import { HeartIcon } from '../../icons/heart';
import { useAppStore } from '../../store/app';
import { useNewsStore } from '../../store/helpers/hooks';

function NewsLike({ newsId, likes, onToggleLike }) {
  const [isFetching, setFetching] = useState(false);
  const { userStore } = useAppStore();
  const { like, dislike } = useNewsStore();
  const likesCount = likes.length;
  const isLiked = likes.find(({ id }) => id === userStore.data.id);
  const onClick = async () => {
    setFetching(true);
    if (isLiked) {
      await dislike(newsId);
    } else {
      await like(newsId);
    }

    if (onToggleLike) {
      await onToggleLike();
    }
    setFetching(false);
  };
  return (
    <div className={styles.container}>
      {likesCount > 0 && <span>{likesCount} понравилось</span>}

      <button
        className={styles.likeBtn}
        type="button"
        onClick={onClick}
        disabled={isFetching}
      >
        <span className={styles.icon}>
          {isFetching ? (
            <Spinner size="sm" animation="grow" role="status" />
          ) : (
            <HeartIcon isFilled={isLiked} />
          )}
        </span>
        Нравится
      </button>
    </div>
  );
}

NewsLike.propTypes = {
  newsId: PropTypes.string.isRequired,
  likes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  onToggleLike: PropTypes.func,
};

export default observer(NewsLike);
