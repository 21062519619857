/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import styles from './chat.module.css';
import { useChatStore } from '../../store/helpers/hooks';
import { useAppStore } from '../../store/app';
import { ChatInput } from './chat-input';

export const ChatModal = observer(() => {
  const { userStore } = useAppStore();
  const { setOpened, sendMessage, messages } = useChatStore();
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToBottom();
    }
  }, [messages]);

  return (
    <div className={styles.chat}>
      <button
        className={styles.close}
        type="button"
        onClick={() => setOpened(false)}
      />
      <div className={styles.title}>Чат Полиса</div>
      <div className={cn(styles.block, styles.messages)}>
        <Scrollbars
          style={{ width: '100%' }}
          autoHeightMax={365}
          autoHeightMin={300}
          ref={scrollRef}
        >
          {messages.map(({ id, player, text }) => {
            const isOwnMessage = player.id === userStore.data.id;
            const { img } = player;
            const { url } = img || {};
            return (
              <div
                key={id}
                className={cn(styles.message, isOwnMessage && styles.own)}
              >
                {!isOwnMessage && (
                  <div
                    className={styles.avatar}
                    style={{ backgroundImage: `url(${url})` }}
                  />
                )}
                <div className={styles.messageInner}>
                  {!isOwnMessage && (
                    <div className={styles.author}>{player.name}</div>
                  )}
                  <div className={styles.text}>{text}</div>
                </div>
              </div>
            );
          })}
        </Scrollbars>
      </div>
      <ChatInput onSubmit={sendMessage} />
    </div>
  );
});
