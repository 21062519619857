import React from 'react';
import io from 'socket.io-client';

import { action, observable, computed, reaction } from 'mobx';
import { APP_STATES, WEBSOCKET_API_URL } from '../constants';
import { TeamStore } from './team';
import { NewsStore } from './news';
import { TasksStore } from './tasks';
import { UserStore } from './user';
import { FaqStore } from './faq';
import { ChatStore } from './chat';
import { MegaChatStore } from './mega-chat';
import { webconfigs } from '../api/webconfigs';
import { ErrorStore } from './error';

const ERROR_MSG = 'Не удалось подключиться к серверу, попробуйте ещё раз.';

/**
 * @typedef {Object} GameData
 * @property {string} name
 * @property {string} team_link
 * @property {string} start_at
 * @property {string} team_name
 * @property {number} team_room
 * @property {string} stream
 * @property {number} rounds
 * @property {number} is_captain
 */

/**
 * @typedef {Object} Team
 * @property {string} name
 * @property {boolean} isSync
 */

export class AppStore {
  @observable state = APP_STATES.INITIAL;

  @observable fetching = true;

  /** @type {string} */
  @observable name = null;

  /** @type {GameData} */
  @observable gameData = {};

  @observable webconfigs = [];

  /** @type {Array<Task>} */
  team = {};

  /** @type {string} */
  errorMsg = null;

  constructor(config) {
    const { token } = config;
    this.token = token;

    this.teamStore = new TeamStore(this);
    this.newsStore = new NewsStore(this);
    this.tasksStore = new TasksStore(this);
    this.userStore = new UserStore(this);
    this.faqStore = new FaqStore(this);
    this.chatStore = new ChatStore(this);
    this.megaChatStore = new MegaChatStore(this);
    this.errorStore = new ErrorStore(this);

    this.init();

    reaction(
      () => this.userStore.teamId,
      teamId => {
        this.closeSocket();
        this.configureSocket(teamId);
        this.listenSocket();
      }
    );
  }

  async init() {
    try {
      this.setFetching(true);
      await this.checkSession();
      await this.loadSettings();
      this.setFetching(false);
    } catch (error) {
      this.setError(
        'Не удалось получить настройки, чтобы запустить приложение.'
      );
      this.setFetching(false);
    }
  }

  @computed
  get isAuthorized() {
    return this.userStore.isAuthorized;
  }

  async loadSettings() {
    try {
      const data = await webconfigs();
      this.setWebconfigs(data);
    } catch (error) {
      throw new Error('ромыч, добавь в БД webconfigs');
    }
  }

  @computed
  get isFinalDay() {
    return Boolean(
      this.webconfigs && this.webconfigs[0] && this.webconfigs[0].show_menu_link
    );
  }

  /**
   * @returns {'email' | 'pin'}
   */
  @computed
  get authorizationType() {
    return (
      (this.webconfigs &&
        this.webconfigs[0] &&
        this.webconfigs[0].authorization_type) ||
      'email'
    );
  }

  @action
  setWebconfigs(data) {
    this.webconfigs = data;
  }

  async checkSession() {
    await this.userStore.checkSession();
  }

  configureSocket() {
    this.socket = io(WEBSOCKET_API_URL, {
      query: {
        team_id: this.userStore.teamId,
      },
    });
  }

  listenSocket() {
    this.socket.open();

    this.socket.on('newMessage', () => {
      this.chatStore.fetchMessages();
    });

    this.socket.on('syncUsers', users => {
      this.teamStore.syncUsers(users);
    });

    this.socket.on('syncError', () => {
      this.teamStore.syncError();
    });

    this.socket.on('syncSuccess', () => {
      this.teamStore.syncSuccess();
    });

    this.socket.on('syncCompleted', () => {
      this.teamStore.syncComplited();
    });
  }

  closeSocket() {
    if (this.socket) {
      this.socket.close();
    }
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }

  @action
  setError(message) {
    this.state = APP_STATES.ERROR;

    this.errorMsg = message || ERROR_MSG;
  }

  publishError = data => this.errorStore.publishError(data);

  cleanErrors = () => this.errorStore.cleanErrors();

  @computed
  get errors() {
    return this.errorStore.data;
  }
}

export const AppStoreContext = React.createContext(null);

export const useAppStore = () => {
  const appStore = React.useContext(AppStoreContext);

  if (!appStore) {
    throw new Error(
      'useAppStore must be used within a AppStoreContext.Provider.'
    );
  }

  return appStore;
};
