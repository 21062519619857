import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import Password from '../password/password';

const initialValues = {
  name: '',
  email: '',
  password: '',
  passwordRepeat: '',
};

function RegistrationForm({ submit, type }) {
  const isEmailType = type === 'email';

  const validate = ({ name, email, password, passwordRepeat, pincode }) => {
    const isPassEqual =
      password === passwordRepeat ? undefined : 'Пароли не совпадают';
    const errors = isEmailType
      ? {
          name: name ? undefined : 'Имя обязательно',
          email: email ? undefined : 'Email обязателен',
          password: password ? isPassEqual : 'password обязателен',
          passwordRepeat: passwordRepeat
            ? isPassEqual
            : 'passwordRepeat обязателен',
        }
      : {
          name: name ? undefined : 'Имя обязательно',
          pincode: pincode ? undefined : 'Пинкод обязателен',
        };
    return JSON.parse(JSON.stringify(errors));
  };
  const submitForm = async ({ email, name, password }) => {
    await submit({ email, name, password });
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validate={validate}
      >
        {({ handleSubmit, isSubmitting, handleChange, errors, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {isEmailType ? (
                <>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="name"
                      id="name"
                      value={values.name}
                      placeholder="Придумайте имя в игре"
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      autoComplete="name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="email"
                      name="email"
                      id="email"
                      value={values.email}
                      placeholder="Email"
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      autoComplete="email"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Password
                      type="password"
                      name="password"
                      id="password"
                      value={values.password}
                      placeholder="Пароль"
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Password
                      type="password"
                      name="passwordRepeat"
                      id="passwordRepeat"
                      value={values.passwordRepeat}
                      placeholder="Повторите пароль"
                      onChange={handleChange}
                      isInvalid={!!errors.passwordRepeat}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.passwordRepeat}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="pincode"
                      id="pincode"
                      value={values.pincode}
                      placeholder="Впишите индивидуальный пинкод"
                      onChange={handleChange}
                      isInvalid={!!errors.pincode}
                      autoComplete="pincode"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pincode}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="name"
                      id="name"
                      value={values.name}
                      placeholder="Придумайте имя в игре"
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      autoComplete="name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}

              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                block
              >
                Далее
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

RegistrationForm.propTypes = {
  submit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['email', 'pincode']),
};

RegistrationForm.defaultProps = {
  type: 'email',
};

export default observer(RegistrationForm);
