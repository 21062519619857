import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import cn from 'classnames';

import commonStyles from '../../styles/common.module.css';
import styles from './personal-rating.module.css';
import { useAppStore } from '../../store/app';
import RatingItem from '../../components/rating/rating-item';
import { Spinner } from '../../components/spinner/spinner';

const PersonalRating = observer(() => {
  const { userStore } = useAppStore();
  const { playersScore, fetching } = userStore;
  useEffect(() => {
    userStore.fetchPlayersScore();
  }, []);
  return (
    <>
      {fetching && <Spinner />}
      <div className={cn(commonStyles.card, styles.top)}>
        <h1 className={cn(commonStyles.titleH2, styles.title)}>
          Личный рейтинг
        </h1>
        <div className={styles.list}>
          {playersScore.length > 0 &&
            playersScore.map((item, index) => {
              const position = index + 1;
              return (
                <RatingItem
                  key={position + item.name}
                  position={position}
                  name={item.name}
                  score={item.score}
                  // teamName={item.team ? item.team.name : undefined}
                  isHighlighted={item.id === userStore.data.id}
                />
              );
            })}
        </div>
      </div>
    </>
  );
});

export default PersonalRating;
