import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  query getMesages($id: IDFilter) {
    messages(where: { team: { id: $id } }) {
      id
      player {
        id
        name
        img {
          url
        }
      }
      team {
        id
      }
      text
      created_at
    }
  }
`;

export const messages = id =>
  gqlClient
    .request(query, {
      id: {
        equals: id,
      },
    })
    .then(({ messages: messagesData }) => messagesData);
