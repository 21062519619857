import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  {
    teams {
      id
      name
      score
    }
  }
`;

export const teamsScore = () =>
  gqlClient.request(query).then(({ teams }) => teams);
