import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './chat.module.css';
import { ArrowUpIcon } from '../../icons/arrow-up';

export const ChatInput = ({ onSubmit, disabled }) => {
  const [text, setText] = useState('');
  const [fetching, setFetching] = useState('');
  const inputRef = useRef(null);

  const send = async value => {
    const trimmedText = (value || text).trim();
    if (!trimmedText) return;

    setFetching(true);
    try {
      await onSubmit(trimmedText);
      setText('');
    } finally {
      setFetching(false);
    }
  };

  const handleChange = event => {
    setText(event.target.value);
  };

  const handleKeyDown = useCallback(e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      send(e.target.value);
    }
  }, []);

  return (
    <div className={styles.inputBlock}>
      <input
        className={styles.input}
        type="text"
        value={text}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        ref={inputRef}
        placeholder="Сообщение"
      />
      <button
        className={styles.inputButton}
        type="button"
        onClick={() => send()}
        disabled={fetching || disabled || text === ''}
      >
        <ArrowUpIcon />
      </button>
    </div>
  );
};

ChatInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
