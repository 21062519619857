/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';
import styles from './mobile-menu.module.css';
import { ROUTES_CONFIG } from '../../routes-config';
import logo from '../../assets/logo-lion.png';

function MobileMenu() {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = isOpened ? 'hidden' : 'initial';
  }, [isOpened]);

  return (
    <>
      <input
        id="menu__toggle"
        className={styles.burgerInput}
        type="checkbox"
        checked={isOpened}
        onChange={() => setIsOpened(!isOpened)}
      />
      <label className={styles.burger} htmlFor="menu__toggle">
        <span></span>
      </label>
      <div className={cn(styles.menu, isOpened && styles.opened)}>
        <div className={styles.menuHeader}>
          <img src={logo} alt="logo" />
        </div>
        <ul className={styles.list}>
          {ROUTES_CONFIG.filter(({ showMenu }) => Boolean(showMenu)).map(
            ({ title, path }) => (
              <li key={path}>
                <Link
                  className={styles.listLink}
                  to={path}
                  onClick={() => setIsOpened(false)}
                >
                  {title}
                </Link>
              </li>
            )
          )}
        </ul>
      </div>
    </>
  );
}

export default MobileMenu;
