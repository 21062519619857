import React from 'react';
import PropTypes from 'prop-types';

export const FaqIcon = ({ isActive = false }) => (
  <i>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9"
        cy="9"
        r="9"
        fill={isActive ? 'url(#faq-icon-paint0_linear)' : 'none'}
      />
      <circle cx="9" cy="9" r="8.25" stroke="#000" strokeWidth="1.5" />
      <path
        d="M8.082 11.2815v-2.583c.861-.042 1.533-.252 2.016-.63.4935-.378.7402-.89775.7402-1.55925v-.2205c0-.5355-.168-.96075-.5039-1.27575-.33605-.3255-.78755-.48825-1.35455-.48825-.609 0-1.09725.17325-1.46475.51975-.357.3465-.5985.7875-.7245 1.323l-1.1655-.441c.0945-.336.231-.65625.4095-.96075.189-.315.42-.588.693-.819.2835-.2415.61425-.4305.99225-.567.378-.1365.81375-.20475 1.30725-.20475.9765 0 1.7483.26775 2.3153.80325.567.5355.8505 1.26.8505 2.1735 0 .504-.0893.945-.2678 1.323-.168.3675-.3885.6825-.6615.945-.273.2625-.5827.4725-.9292.63-.34655.1575-.69305.26775-1.03955.33075v1.701H8.082zm.61425 3.465c-.336 0-.58275-.084-.74025-.252-.147-.168-.2205-.3885-.2205-.6615v-.2047c0-.273.0735-.4935.2205-.6615.1575-.1681.40425-.252.74025-.252.336 0 .5775.0839.7245.252.1575.168.23625.3885.23625.6615v.2047c0 .273-.07875.4935-.23625.6615-.147.168-.3885.252-.7245.252z"
        fill="#000"
        stroke="#000"
        strokeWidth=".5"
      />
      <defs>
        <linearGradient
          id="faq-icon-paint0_linear"
          x1="9"
          y1="0"
          x2="9"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14C9D5" />
          <stop offset="1" stopColor="#C5F9FF" />
        </linearGradient>
      </defs>
    </svg>
  </i>
);

FaqIcon.propTypes = {
  isActive: PropTypes.bool,
};
