import React from 'react';
import PropTypes from 'prop-types';

export const TasksIcon = ({ isActive = false }) => (
  <i>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        fill={isActive ? 'url(#tasks-icon-paint0_linear)' : 'none'}
      />
      <path
        d="M18.4854 3.46085c-.2648-.26525-.6945-.26571-.9593-.00113L9.38771 11.5767 6.45139 8.38754c-.25373-.27541-.6827-.29327-.95857-.03959-.27566.25373-.29331.68292-.03959.95858l3.41459 3.70827c.12505.136.30006.2149.48459.2187.00496.0002.00974.0002.01449.0002.17933 0 .35184-.0712.47893-.1978L18.484 4.4203c.2655-.26455.266-.6942.0014-.95945z"
        fill="#000"
        stroke="#000"
        strokeWidth=".2"
      />
      <path
        d="M18.3216 9.32159c-.3747 0-.6784.30368-.6784.67841 0 4.2146-3.4286 7.6432-7.6432 7.6432-4.21439 0-7.64321-3.4286-7.64321-7.6432 0-4.21439 3.42882-7.64321 7.64321-7.64321.3747 0 .6784-.30368.6784-.67838C10.6784 1.30368 10.3747 1 10 1c-4.96266 0-9 4.03734-9 9 0 4.9624 4.03734 9 9 9 4.9624 0 9-4.0376 9-9 0-.3747-.3037-.67841-.6784-.67841z"
        fill="#000"
        stroke="#000"
        strokeWidth=".2"
      />
      <defs>
        <linearGradient
          id="tasks-icon-paint0_linear"
          x1="9.99964"
          y1="19.0001"
          x2="9.99964"
          y2="1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE6C0" />
          <stop offset="1" stopColor="#FF8CA7" />
        </linearGradient>
      </defs>
    </svg>
  </i>
);

TasksIcon.propTypes = {
  isActive: PropTypes.bool,
};
