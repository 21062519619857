/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';

import styles from './faq-item.module.css';
import commonStyles from '../../styles/common.module.css';
import arrowDown from '../../assets/arrow-down.svg';

function FaqItem({ title, text }) {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className={cn(styles.card)}>
      <div className={styles.topWrapper} onClick={() => setIsOpened(!isOpened)}>
        <div className={styles.top}>
          <div className={cn(commonStyles.titleH2, styles.title)}>{title}</div>
        </div>
        <div className={styles.arrowIconWrapper}>
          <img
            className={cn(styles.arrowIcon, isOpened && styles.opened)}
            src={arrowDown}
            alt="arrow down"
          />
        </div>
      </div>
      <div className={cn(styles.content, isOpened && styles.opened)}>
        <ReactMarkdown className={styles.text}>{text}</ReactMarkdown>
      </div>
    </div>
  );
}

FaqItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default FaqItem;
