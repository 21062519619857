import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  mutation updateTeam($id: ID!, $data: TeamUpdateInput!) {
    updateTeam(where: { id: $id }, data: $data) {
      id
      name
      synchronized_at
      synchronized_players {
        id
        name
        img {
          url
        }
      }
    }
  }
`;

export const sync = ({ teamId, player }) =>
  gqlClient
    .request(query, {
      id: teamId,
      data: {
        synchronized_players: {
          connect: [
            {
              id: player,
            },
          ],
        },
      },
    })
    .then(({ updateTeam }) => updateTeam);
