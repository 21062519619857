/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
// import cn from 'classnames';

import { Button } from 'react-bootstrap';
import styles from './active-tasks.module.css';
import classes from '../../styles/common.module.css';
import { useTasksStore, useUserStore } from '../../store/helpers/hooks';
import { Spinner } from '../../components/spinner/spinner';
import TaskActive from '../../components/task/task-active';
import { Modal } from '../../components/modal/modal';
import { TaskEmpty } from '../../components/task/task-empty';

function ActiveTasks() {
  const { loadUserDataById } = useUserStore();
  const {
    commonTasks,
    fetch,
    fetching,
    updateAnswer,
    createAnswer,
  } = useTasksStore();
  const [showErrorModal, setShowErrorModal] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(null);
  const submit = async ({ value, task, timer, answerId, answerCreatedAt }) => {
    if (timer) {
      if (!answerCreatedAt) {
        await createAnswer({ value, task });
        await fetch();
      } else {
        const { score, text } = await updateAnswer({
          answerId,
          value,
        });

        if (score > 0) {
          loadUserDataById();
          setShowSuccessModal(text);
        } else {
          setShowErrorModal(
            value ? text : 'Время, отведенное на выполнение задания, вышло.'
          );
        }
      }
    } else {
      const { score, text } = await createAnswer({ value, task });

      if (score > 0) {
        loadUserDataById();
        setShowSuccessModal(text);
      } else {
        setShowErrorModal(text);
      }
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleHideErrorModal = () => {
    setShowErrorModal(null);
    fetch();
  };

  const handleHideSuccessModal = () => {
    setShowSuccessModal(null);
    fetch();
  };

  return (
    <div className={classes.container}>
      {fetching && <Spinner />}
      {commonTasks.length > 0 ? (
        commonTasks.map(({ ...props }) => (
          <div key={props.id} className={classes.item}>
            <TaskActive {...props} onSubmit={submit} />
          </div>
        ))
      ) : (
        <TaskEmpty description="Сейчас у вас нет доступных активных заданий, попробуйте зайти позже." />
      )}
      <Modal
        show={Boolean(showErrorModal)}
        onHide={handleHideErrorModal}
        size="sm"
        backdropClassName={styles.error}
      >
        <div className={styles.modalText}>{showErrorModal}</div>
        <div className={styles.btn}>
          <Button block variant="success" onClick={handleHideErrorModal}>
            Ок
          </Button>
        </div>
      </Modal>
      <Modal
        show={Boolean(showSuccessModal)}
        onHide={handleHideSuccessModal}
        size="sm"
        backdropClassName={styles.success}
      >
        <div className={styles.modalText}>{showSuccessModal}</div>
        <div className={styles.btn}>
          <Button block variant="success" onClick={handleHideSuccessModal}>
            Ок
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default observer(ActiveTasks);
