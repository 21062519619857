import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import AuthForm from '../../components/auth-form/auth-form';
import { PageSpinner } from '../../components/page-spinner/page-spinner';
import { HOMEPAGE_PATH } from '../../path';

// import classes from './auth.module.css';
import { useAppStore } from '../../store/app';

function Auth() {
  const {
    userStore,
    isAuthorized,
    publishError,
    cleanErrors,
    authorizationType,
  } = useAppStore();
  const [fetching, setFetching] = useState(false);
  const submit = async values => {
    setFetching(true);
    try {
      const response = await userStore.signIn(values);
      if (response) {
        throw new Error(response);
      }
      cleanErrors();
    } catch (error) {
      publishError({
        text: error.message,
      });
    } finally {
      setFetching(false);
    }
  };

  if (isAuthorized) {
    return <Redirect to={HOMEPAGE_PATH} />;
  }

  return (
    <>
      {fetching && <PageSpinner />}
      <AuthForm type={authorizationType} submit={submit} />
    </>
  );
}

export default Auth;
