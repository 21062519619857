import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  mutation createMessage($data: MessageCreateInput!) {
    createMessage(data: $data) {
      id
      created_at
      text
      player {
        name
        id
      }
      team {
        id
        name
      }
    }
  }
`;

export const sendMessage = ({ player, team, text }) =>
  gqlClient
    .request(query, {
      data: {
        text,
        player: { connect: { id: player } },
        team: { connect: { id: team } },
      },
    })
    .then(({ createMessage }) => createMessage);
