import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';

import { observer } from 'mobx-react-lite';
import styles from './news-item.module.css';
import commonStyles from '../../styles/common.module.css';
import NewsLike from '../news-like/news-like';

function NewsItem({ id, title, text, date, img, teaser, likes, onToggleLike }) {
  const [isOpened, setIsOpened] = useState(!text || !teaser);
  return (
    <div className={styles.card}>
      <div
        className={cn(commonStyles.titleH2, styles.title)}
        style={{ backgroundImage: `url(${img})` }}
      >
        <span className={styles.titleText}>{title}</span>
      </div>
      <div className={styles.date}>{date}</div>
      {teaser && (
        <div className={styles.text}>
          <ReactMarkdown>{teaser}</ReactMarkdown>
          {!isOpened && (
            <button
              className={styles.showMore}
              type="button"
              onClick={() => setIsOpened(true)}
            >
              Читать далее
            </button>
          )}
        </div>
      )}
      {isOpened && text && (
        <ReactMarkdown className={styles.text}>{text}</ReactMarkdown>
      )}
      <div className={styles.bottom}>
        <NewsLike newsId={id} likes={likes} onToggleLike={onToggleLike} />
      </div>
    </div>
  );
}

NewsItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  teaser: PropTypes.string,
  text: PropTypes.string,
  date: PropTypes.string,
  img: PropTypes.string,
  likes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  onToggleLike: PropTypes.func,
};

export default observer(NewsItem);
