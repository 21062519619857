import React from 'react';

export const SyncIcon = () => (
  <i>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.42818 14.8381c-2.95186-.2627-5.26412-2.8116-5.26412-5.80294 0-1.95339.99978-3.82504 2.58037-4.89154l.68916.91888c.266.35459.82596.24149.93347-.18851L8.42175.655242C8.505.322242 8.25261 0 7.91016 0H3.69141c-.43355 0-.68235.496441-.42188.84375l.5709.7612C1.45804 3.27393 0 6.06909 0 9.03516c0 4.51194 3.35099 8.34944 7.79474 8.92624.26413.0344.5143-.1342.57948-.395l.5188-2.0752c.07854-.3141-.14235-.6244-.46484-.6531zM15.7558 3.02797C14.3144 1.37774 12.3431.316055 10.2053.0385312 9.93969.00404297 9.69071.173953 9.62582.433582L9.10695 2.50875c-.07851.31416.14234.62448.46487.65317C12.5237 3.42461 14.8359 5.98897 14.8359 9c0 1.9653-.9778 3.7866-2.5797 4.8572l-.6898-.9197c-.266-.3546-.826-.2415-.9335.1885l-1.05465 4.2188c-.08325.333.16914.6552.51155.6552h4.2188c.4335 0 .6823-.4965.4219-.8438l-.5708-.761c1.0799-.7556 1.9892-1.7517 2.6504-2.9087C17.5885 12.1242 18 10.5728 18 9c0-2.19431-.797-4.31522-2.2442-5.97203z"
        fill="#000"
      />
    </svg>
  </i>
);
