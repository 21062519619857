import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  {
    posts(orderBy: { published_at: desc }) {
      title
      id
      text
      teaser
      likes {
        id
      }
      published_at
      is_active
      img {
        url
      }
    }
  }
`;

export const news = () => gqlClient.request(query).then(({ posts }) => posts);
