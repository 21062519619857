/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';

import { Button } from 'react-bootstrap';
import styles from './task.module.css';
import commonStyles from '../../styles/common.module.css';
import TaskOptions from '../task-options/task-options';
import SimpleQuestion from '../simple-question/simple-question';
import { TASK_TYPE } from '../../constants';
import { ExpiredAt } from '../expired-at/expired-at';
import { pluralScore } from '../../helpers/pluralize';
import { ConfirmModal } from '../confirm-modal/confirm-modal';

function TaskActive({
  id,
  title,
  teaser,
  text,
  options,
  score,
  expired_at,
  closed,
  type,
  img,
  timer,
  onSubmit,
  answer,
}) {
  const answerSafe = answer || {};
  const { created_at } = answerSafe;
  const [isOpened, setIsOpened] = useState(Boolean(created_at));
  const submit = value => {
    return onSubmit({
      value,
      task: id,
      timer,
      answerId: answerSafe.id,
      answerCreatedAt: created_at,
    });
  };
  const [showConfirmModal, setShowConfirmModal] = useState(null);
  return (
    <div className={cn(styles.card, score === 0 && styles.gray)}>
      {img && (
        <div
          className={styles.image}
          style={img ? { backgroundImage: `url(${img.url})` } : undefined}
          alt={title}
        />
      )}
      <div className={styles.topWrapper}>
        <div className={styles.top}>
          <div className={cn(commonStyles.titleH2, styles.title, styles.big)}>
            {title}
          </div>
          <ReactMarkdown className={styles.teaser}>{teaser}</ReactMarkdown>

          <div className={styles.score}>
            <div className={styles.scoreValue}>{score}</div>
            {pluralScore(score)}
          </div>
          {!isOpened && (
            <div className={styles.startBlock}>
              <Button
                onClick={() => {
                  if (timer) {
                    setShowConfirmModal(true);
                  } else {
                    setIsOpened(true);
                  }
                }}
                variant="success"
                size="sm"
              >
                Приступить
              </Button>
              {!closed && (
                <div className={styles.expiredAt}>
                  <ExpiredAt value={expired_at} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={cn(styles.content, isOpened && styles.opened)}>
        <ReactMarkdown className={styles.text}>{text}</ReactMarkdown>
        {!closed && isOpened && (
          <div className={styles.answer}>
            {/* TODO: вынести таймер */}
            {options ? (
              <TaskOptions
                options={options}
                onSubmit={submit}
                multiple={type === TASK_TYPE.CHECK}
                timer={timer}
                timerStartTime={created_at}
              />
            ) : (
              <SimpleQuestion
                submit={submit}
                timer={timer}
                timerStartTime={created_at}
              />
            )}
          </div>
        )}
      </div>
      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setIsOpened(true);
          submit('');
          setShowConfirmModal(false);
        }}
        onDecline={() => setShowConfirmModal(false)}
        title="Вы готовы приступить к заданию?"
        text="Сразу после подтверждения запустится таймер"
      />
    </div>
  );
}

TaskActive.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  teaser: PropTypes.string,
  text: PropTypes.string,
  options: PropTypes.arrayOf({
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  score: PropTypes.number,
  expired_at: PropTypes.number,
  closed: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(TASK_TYPE)),
  img: PropTypes.string,
  timer: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  answer: PropTypes.shape({
    value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    created_at: PropTypes.string,
  }),
};

export default TaskActive;
