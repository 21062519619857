import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import UserProfile from '../../components/user-profile/user-profile';
import commonStyles from '../../styles/common.module.css';
import styles from './profile.module.css';
import { useAppStore } from '../../store/app';
import { HOMEPAGE_PATH } from '../../path';
import Team from '../../components/team/team';
import { Spinner } from '../../components/spinner/spinner';

const Profile = observer(() => {
  const { userStore, teamStore } = useAppStore();
  const {
    data: { name, email, score, img, team },
    hasTeam,
  } = userStore;
  const { url } = img || {};
  const { players, fetching } = teamStore;

  useEffect(() => {
    if (hasTeam) {
      teamStore.fetchTeam();
      teamStore.fetchPlayers();
    }
  }, [hasTeam]);

  return (
    <>
      <div className={cn(commonStyles.card, styles.top)}>
        <Link
          className={styles.signOut}
          to={HOMEPAGE_PATH}
          onClick={() => userStore.signOut()}
        >
          Выход
        </Link>
        <UserProfile name={name} email={email} score={score} img={url} />
      </div>
      {hasTeam && (
        <div className={cn(commonStyles.card, styles.bottom)}>
          {fetching && <Spinner />}
          <Team name={team.name} score={team.score} players={players} />
        </div>
      )}
    </>
  );
});

export default Profile;
