import React from 'react';
import RestoreSession from '../../components/restore-session/restore-session';

import { LiveAuthForm } from '../../components/live-auth-form/live-auth-form';
import { useMegaChatStore } from '../../store/helpers/hooks';

export const LiveAuth = () => {
  const {
    connect,
    restoreSession,
    savedSession,
    savedSessionToken,
  } = useMegaChatStore();
  return (
    <div>
      <LiveAuthForm submit={connect} />
      {savedSession && savedSessionToken && (
        <RestoreSession userName={savedSession} submit={restoreSession} />
      )}
    </div>
  );
};
