/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import styles from './chat.module.css';
import { useChatStore } from '../../store/helpers/hooks';
import { ChatIcon } from '../../icons/chat';
import { ChatModal } from './chat-modal';

export const Chat = observer(() => {
  const { isOpened, setOpened, fetchMessages, messages } = useChatStore();
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    if (isOpened) {
      fetchMessages();
    }
  }, [isOpened]);

  useEffect(() => {
    let timer;
    if (!isOpened) {
      setAnimated(true);
      timer = setTimeout(() => setAnimated(false), 1500);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [messages, isOpened]);

  return (
    <>
      {isOpened ? (
        <div className={styles.chatModal}>
          <ChatModal />
        </div>
      ) : (
        <button
          className={cn(styles.chatButton, { [styles.bounced]: animated })}
          type="button"
          onClick={() => setOpened(true)}
        >
          <ChatIcon />
        </button>
      )}
      {}
    </>
  );
});
