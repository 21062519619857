/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import styles from './chat.module.css';
import { useMegaChatStore } from '../../store/helpers/hooks';
import { ChatInput } from './chat-input';

export const MegaChat = observer(() => {
  const {
    sendMessage,
    messages,
    token: userToken,
    fetchMessages,
  } = useMegaChatStore();
  const scrollRef = useRef(null);

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToBottom();
    }
  }, [messages]);

  return (
    <div className={styles.chat}>
      <div className={styles.title}>Мегачат</div>
      <div className={cn(styles.block, styles.messages)}>
        <Scrollbars
          style={{ width: '100%' }}
          autoHeightMax={365}
          autoHeightMin={300}
          ref={scrollRef}
        >
          {messages.length > 0 &&
            messages.map(({ id, name, token, text, bold }) => {
              const isOwnMessage = token === userToken;
              return (
                <div
                  key={id}
                  className={cn(
                    styles.message,
                    isOwnMessage && styles.own,
                    bold && styles.bold,
                    styles.withoutAvatar
                  )}
                >
                  <div className={styles.messageInner}>
                    {!isOwnMessage && (
                      <div className={styles.author}>{name}</div>
                    )}
                    <div className={styles.text}>{text}</div>
                  </div>
                </div>
              );
            })}
        </Scrollbars>
      </div>
      <ChatInput onSubmit={sendMessage} />
    </div>
  );
});
