import { useAppStore } from '../app';

export const useQuestionStore = () => {
  const appStore = useAppStore();

  return appStore.questionStore;
};

export const useTeamStore = () => {
  const appStore = useAppStore();

  return appStore.teamStore;
};

export const useNewsStore = () => {
  const appStore = useAppStore();

  return appStore.newsStore;
};

export const useTasksStore = () => {
  const appStore = useAppStore();

  return appStore.tasksStore;
};

export const useFaqStore = () => {
  const appStore = useAppStore();

  return appStore.faqStore;
};

export const useChatStore = () => {
  const appStore = useAppStore();

  return appStore.chatStore;
};

export const useUserStore = () => {
  const appStore = useAppStore();

  return appStore.userStore;
};

export const useMegaChatStore = () => {
  const appStore = useAppStore();

  return appStore.megaChatStore;
};

export const useErrorStore = () => {
  const appStore = useAppStore();

  return appStore.errorStore;
};
