import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './route-layout.module.css';
import commonStyles from '../../styles/common.module.css';
import logo from '../../assets/logo-lion.png';

function RouteLayoutAuthorization({
  children,
  title,
  backgroundText,
  layoutColor,
  backgroundImage,
  text,
}) {
  return (
    <div
      className={cn(styles.container, styles[layoutColor])}
      style={
        backgroundImage
          ? { backgroundImage: `url(${backgroundImage})` }
          : undefined
      }
    >
      <div className={styles.backgroundWrapper}>
        <div className={cn(styles.backgroundGradient, styles[layoutColor])} />
        <span
          className={cn(
            styles.backgroundText,
            styles[layoutColor],
            styles.auth
          )}
        >
          {backgroundText}
        </span>
      </div>

      <div className={styles.contentWrapperAuth}>
        <div>
          <div className={styles.logo}>
            <img src={logo} alt="полюс" />
          </div>
        </div>
        <div className={styles.content}>
          <h1 className={cn(commonStyles.titleH1, styles.title, styles.auth)}>
            {title}
          </h1>
          <p className={styles.text}>{text}</p>
          {children}
        </div>
      </div>
    </div>
  );
}

RouteLayoutAuthorization.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  backgroundText: PropTypes.string,
  backgroundImage: PropTypes.string,
  layoutColor: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default RouteLayoutAuthorization;
