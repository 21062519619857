import React from 'react';
// import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
// import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';

// const initialValues = {
//   email: '',
// };

function RestoreForm() {
  // const validate = ({ name }) => {
  //   const errors = {
  //     name: name ? undefined : 'Имя обязательно',
  //   };
  //   return JSON.parse(JSON.stringify(errors));
  // };
  // const submitForm = async ({ email }) => {
  //   await submit(email);
  // };

  return (
    <div>
      {/* <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        // validate={validate}
      >
        {({ handleSubmit, isSubmitting, handleChange, errors, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="email"
                  id="email"
                  value={values.email}
                  placeholder="Введите e-mail"
                  onChange={handleChange}
                  // isInvalid={!!errors.email}
                  autoComplete="email"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                block
              >
                Сбросить пароль
              </Button>
            </Form>
          );
        }}
      </Formik> */}
      <a href="mailto:help@summerinthecity.ru" target="_blank" rel="noreferrer">
        <Button variant="primary" type="submit" block>
          Написать в техподдержку
        </Button>
      </a>
    </div>
  );
}

RestoreForm.propTypes = {
  // submit: PropTypes.func.isRequired,
};

export default observer(RestoreForm);
