import React from 'react';
import { Button } from 'react-bootstrap';
import cn from 'classnames';

import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import commonStyles from '../../styles/common.module.css';
import styles from './avatar-uploader.module.css';
import { useAppStore } from '../../store/app';
import { PhotoUploader } from '../../components/photo-uploader/photo-uploader';
import { HOMEPAGE_PATH } from '../../path';

export const AvatarUploader = observer(() => {
  const { userStore } = useAppStore();
  const history = useHistory();
  const { data } = userStore;
  const { img } = data || {};
  const { url } = img || {};
  return (
    <div className={cn(commonStyles.card, styles.card)}>
      <div className={styles.title}>
        Чтобы завершить регистрацию, загрузите свой аватар
      </div>
      <div
        className={styles.avatar}
        style={{ backgroundImage: `url(${url})` }}
      />
      <PhotoUploader
        name="photo"
        // url={photo}
        // onUpload={uploadedUrl => setFieldValue('photo', uploadedUrl)}
      />
      <Button onClick={() => history.push(HOMEPAGE_PATH)} block>
        Подтвердить
      </Button>
    </div>
  );
});
