import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './route-layout.module.css';
import commonStyles from '../../styles/common.module.css';
import { Menu } from '../menu/menu';

function RouteLayout({
  children,
  title,
  backgroundText,
  layoutColor,
  backgroundImage,
  hideSidebar,
}) {
  return (
    <div
      className={cn(styles.container, styles[layoutColor])}
      style={
        backgroundImage
          ? { backgroundImage: `url(${backgroundImage})` }
          : undefined
      }
    >
      <div className={styles.backgroundWrapper}>
        <div className={cn(styles.backgroundGradient, styles[layoutColor])} />
        <span className={cn(styles.backgroundText, styles[layoutColor])}>
          {backgroundText}
        </span>
      </div>
      <div
        className={cn(styles.contentWrapper, hideSidebar && styles.hideSidebar)}
      >
        <div className={styles.desktopMenu}>
          <Menu />
        </div>
        <div className={styles.content}>
          <h1 className={cn(commonStyles.titleH1, styles.title)}>{title}</h1>
          {children}
        </div>
      </div>
    </div>
  );
}

RouteLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  backgroundText: PropTypes.string,
  backgroundImage: PropTypes.string,
  layoutColor: PropTypes.string.isRequired,
  hideSidebar: PropTypes.bool,
};

export default RouteLayout;
