import React from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Layout from '../components/layout/layout';
import RouteLayout from '../components/route-layout/route-layout';
import { ROUTES_CONFIG } from '../routes-config';
import { useAppStore } from '../store/app';
import { Spinner } from '../components/spinner/spinner';
import { PATH } from '../path';
import RouteLayoutAuthorization from '../components/route-layout/route-layout-authorization';
import { LivePage } from './live';

function Routes() {
  const { isAuthorized, fetching, errorMsg } = useAppStore();
  const { pathname } = useLocation();
  const isAuthorization =
    PATH.AUTH.includes(pathname) ||
    PATH.REGISTRATION.includes(pathname) ||
    PATH.RESTORE.includes(pathname) ||
    PATH.AVATAR_UPLOADER.includes(pathname);
  const routes = ROUTES_CONFIG.map(
    ({
      path,
      Component,
      title,
      backgroundText,
      backgroundImage,
      layoutColor,
      isAvailableForNotAuthorized,
      text,
    }) => {
      const RouteLayoutComponent =
        path === PATH.AUTH ||
        path === PATH.REGISTRATION ||
        path === PATH.RESTORE ||
        path === PATH.AVATAR_UPLOADER
          ? RouteLayoutAuthorization
          : RouteLayout;
      return isAuthorized || isAvailableForNotAuthorized ? (
        <Route
          key={path}
          path={path}
          render={() => (
            <Layout isAuthorization={isAuthorization}>
              <RouteLayoutComponent
                isAuthorized={isAuthorized}
                title={title}
                backgroundText={backgroundText || title}
                layoutColor={layoutColor}
                backgroundImage={backgroundImage}
                text={text}
              >
                <Component />
              </RouteLayoutComponent>
            </Layout>
          )}
          exact
        />
      ) : (
        <Redirect key={path} from={path} to="/auth" />
      );
    }
  );

  if (errorMsg) {
    return errorMsg;
  }

  if (fetching) {
    return <Spinner />;
  }

  return (
    <Switch>
      <Route path={PATH.LIVE} exact component={LivePage} />

      {routes}
      <Route path="*" component={() => '404'} />
    </Switch>
  );
}

export default observer(Routes);
