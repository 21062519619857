import { observable, action } from 'mobx';

/**
 * @typedef ErrorRecord
 * @property {string} id
 * @property {string} title
 * @property {string} text
 */

export class ErrorStore {
  initialData = [];

  @observable fetching = false;

  /** @type {ErrorRecord[]} */
  @observable data = this.initialData;

  constructor(appStore) {
    this.appStore = appStore;
  }

  @action
  setData(data) {
    this.data = data;
  }

  @action
  publishError({
    title = 'Ошибка',
    text = 'Неизвестная ошибка, попробуйте ещё раз.',
  } = {}) {
    const newData = this.data;
    newData.push({ id: Date.now().toString(), title, text });
    this.data = newData;
  }

  @action.bound
  removeError(id) {
    this.data = this.data.filter(item => item.id !== id);
  }

  @action.bound
  cleanErrors() {
    this.data = this.initialData;
  }
}
