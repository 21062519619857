import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  query getPlayers($id: IDFilter, $isHidden: BooleanFilter) {
    players(where: { team: { id: $id, is_hidden: $isHidden } }) {
      id
      name
      score
      img {
        url
      }
      is_active
    }
  }
`;

export const players = id =>
  gqlClient
    .request(query, {
      id: {
        equals: id,
      },
      isHidden: {
        not: {
          equals: true,
        },
      },
    })
    .then(({ players: playersData }) => playersData);
