import { differenceInSeconds, parseISO } from 'date-fns';

export const saveName = name => localStorage.setItem('quiz_heroes_name', name);

export const loadName = () => localStorage.getItem('quiz_heroes_name');

export const setStorageItem = (key, value) => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem(key, value);
      // eslint-disable-next-line no-empty
    } catch (e) {
      reject(new Error('Не удалось сохранить сессию'));
    }
  });
};

export const getStorageItem = key => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return null;
  }
};

export const isTaskWithTimerActive = (createdAt, timeout) => {
  const parsedDate = parseISO(createdAt);
  return differenceInSeconds(parsedDate, new Date()) < timeout;
};
