import { observable, action, computed, reaction } from 'mobx';
import io from 'socket.io-client';

import { megaMessages } from '../api/mega-messages';
import { megaSendMessage } from '../api/mega-send-message';
import { megaReg } from '../api/mega-reg';
import { WEBSOCKET_CHAT_API_URL } from '../constants';
import { getStorageItem, setStorageItem } from '../helpers';

const USER_NAME_STORAGE_KEY = 'quiz-sinergy-last-user';
const USER_TOKEN_STORAGE_KEY = 'quiz-sinergy-last-user-token';

export class MegaChatStore {
  initialData = [];

  @observable fetching = false;

  @observable data = this.initialData;

  @observable name = '';

  @observable token = '';

  constructor(appStore) {
    this.appStore = appStore;

    this.fetchMessages = this.fetchMessages.bind(this);

    reaction(
      () => this.token,
      () => {
        this.configureSocket();
        this.listenSocket();
      }
    );
  }

  @computed
  get isAuthorized() {
    return Boolean(this.name && this.token);
  }

  @computed
  get messages() {
    return this.data;
  }

  // eslint-disable-next-line class-methods-use-this
  get savedSession() {
    return getStorageItem(USER_NAME_STORAGE_KEY);
  }

  // eslint-disable-next-line class-methods-use-this
  get savedSessionToken() {
    return getStorageItem(USER_TOKEN_STORAGE_KEY);
  }

  connect = async ({ name }) => {
    try {
      const { data } = await megaReg(
        this.token || this.savedSessionToken,
        name
      );
      const { status, token, reason } = data;
      if (status === 'error') {
        throw new Error(reason);
      }
      this.setName(name);
      this.setToken(token);
      this.saveSession();
    } catch (error) {
      console.log(error);
      this.appStore.publishError();
    }
  };

  saveSession() {
    setStorageItem(USER_NAME_STORAGE_KEY, this.name);
    setStorageItem(USER_TOKEN_STORAGE_KEY, this.token);
  }

  restoreSession = async () => {
    this.setToken(this.savedSessionToken);
    await this.connect({ name: this.savedSession });
  };

  configureSocket() {
    this.socket = io(WEBSOCKET_CHAT_API_URL);
  }

  listenSocket() {
    this.socket.on('mega_update_messages', () => {
      this.fetchMessages();
    });
  }

  fetchMessages = async () => {
    const { data } = await megaMessages();

    if (data) {
      this.setData(data);
    }
  };

  sendMessage = async text => {
    try {
      await megaSendMessage(this.token, text);
    } catch (error) {
      this.appStore.publishError({
        text: 'Не удалось отправить сообщение, попробуйте ещё раз.',
      });
    }
  };

  @action
  setData(data) {
    this.data = data;
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }

  @action.bound
  setName(value) {
    this.name = value;
  }

  @action.bound
  setToken(value) {
    this.token = value;
  }
}
