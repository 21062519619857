/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
// import cn from 'classnames';

import classes from '../../styles/common.module.css';
import { useTasksStore } from '../../store/helpers/hooks';
import Task from '../../components/task/task';
import { Spinner } from '../../components/spinner/spinner';
import { TaskEmpty } from '../../components/task/task-empty';

function ClosedTasks() {
  const { closedTasks, fetch, fetching } = useTasksStore();

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={classes.container}>
      {fetching && <Spinner />}
      {closedTasks.length > 0 ? (
        closedTasks.map(props => (
          <div key={props.title} className={classes.item}>
            <Task {...props} closed />
          </div>
        ))
      ) : (
        <TaskEmpty description="Пока у вас нет завершенных заданий." />
      )}
    </div>
  );
}

export default observer(ClosedTasks);
