/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import styles from './password.module.css';

const Password = ({ ...rest }) => {
  const [state, setState] = useState('password');
  return (
    <div className={styles.password}>
      <Form.Control {...rest} type={state} />
      <span
        className={styles.toggler}
        onClick={() => setState(state === 'password' ? 'text' : 'password')}
      >
        {state === 'password' ? 'Показать' : 'Скрыть'}
      </span>
    </div>
  );
};

export default Password;
