/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';

import styles from './task.module.css';
import commonStyles from '../../styles/common.module.css';
import TaskOptions from '../task-options/task-options';
import SimpleQuestion from '../simple-question/simple-question';
import { TASK_TYPE } from '../../constants';
import { ExpiredAt } from '../expired-at/expired-at';
import { pluralScore } from '../../helpers/pluralize';

function Task({
  title,
  text,
  options,
  score,
  expired_at,
  is_team,
  closed,
  type,
  img,
  teaser,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const submit = value => alert(value);
  return (
    <div className={cn(styles.card, score === 0 && styles.gray)}>
      <div className={styles.topWrapper}>
        {is_team && <span className={styles.teamModeLabel} />}
        <div
          className={cn(styles.top, styles.clickable)}
          onClick={() => setIsOpened(!isOpened)}
        >
          <div className={cn(commonStyles.titleH2, styles.title)}>{title}</div>
          {!closed && (
            <div className={styles.expiredAt}>
              <ExpiredAt value={expired_at} />
            </div>
          )}
          <div className={cn(styles.score, styles.right)}>
            <div className={styles.scoreValue}>{score}</div>
            {pluralScore(score)}
          </div>
        </div>
      </div>
      <div className={cn(styles.content, isOpened && styles.opened)}>
        <ReactMarkdown className={styles.text}>{teaser}</ReactMarkdown>
        <ReactMarkdown className={styles.text}>{text}</ReactMarkdown>
        {img && <img className={styles.imageImg} src={img.url} alt={title} />}
        {!closed && (
          <div className={styles.answer}>
            {options ? (
              <TaskOptions
                options={options}
                onSubmit={submit}
                multiple={type === TASK_TYPE.CHECK}
              />
            ) : (
              <SimpleQuestion submit={submit} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

Task.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  options: PropTypes.arrayOf({
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  score: PropTypes.number,
  expired_at: PropTypes.number,
  is_team: PropTypes.bool,
  closed: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(TASK_TYPE)),
  img: PropTypes.string,
  teaser: PropTypes.string,
};

export default Task;
