/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal } from 'react-bootstrap';

import styles from './modal.module.css';

export const Modal = ({ show, onHide, children, ...rest }) => {
  return (
    <BootstrapModal show={show} onHide={onHide} centered {...rest}>
      <BootstrapModal.Body className={styles.body}>
        {children}
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

Modal.Title = ({ children }) => <div className={styles.title}>{children}</div>;

Modal.Subtitle = ({ children }) => (
  <div className={styles.subtitle}>{children}</div>
);

Modal.Actions = ({ children }) => (
  <div className={styles.actions}>{children}</div>
);

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node,
  closable: PropTypes.bool,
};

Modal.Title.propTypes = {
  children: PropTypes.node,
};

Modal.Subtitle.propTypes = {
  children: PropTypes.node,
};

Modal.Actions.propTypes = {
  children: PropTypes.node,
};
