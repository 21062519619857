import { observer } from 'mobx-react-lite';
import React from 'react';

import styles from './profile-card.module.css';
import { useAppStore } from '../../store/app';
import { pluralScore } from '../../helpers/pluralize';

export const ProfileCard = observer(() => {
  const { userStore } = useAppStore();
  const {
    data: { name, img, score = 0 },
  } = userStore;
  const { url } = img || {};
  return (
    <div className={styles.profileCard}>
      <div className={styles.name}>{name}</div>
      <div
        className={styles.avatar}
        style={{ backgroundImage: `url(${url})` }}
      />
      <div className={styles.score}>
        <span className={styles.value}>{score}</span>
        &nbsp;
        {pluralScore(score)}
      </div>
    </div>
  );
});
