import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { pluralScore } from '../../helpers/pluralize';
import styles from './rating-item.module.css';
// import { useAppStore } from '../../store/app';

const RatingItem = ({
  position,
  name,
  score,
  teamName,
  isHighlighted = false,
  isTeamRating = false,
}) => {
  // const { userStore } = useAppStore();
  // const {
  //   data: { name, email, score },
  // } = userStore;
  return (
    <>
      <div
        className={cn(styles.ratingItem, isHighlighted && styles.highlighted)}
      >
        <div className={styles.position}>{position}</div>
        <div className={styles.name}>{name}</div>
        <div className={cn(styles.score, isTeamRating && styles.blue)}>
          <span className={styles.value}>{score}</span>
          {pluralScore(score)}
        </div>
        {teamName && <div className={styles.team}>{teamName}</div>}
      </div>
    </>
  );
};

RatingItem.propTypes = {
  position: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  teamName: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
  isTeamRating: PropTypes.bool,
};

export default RatingItem;
