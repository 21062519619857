import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  mutation($id: ID!, $data: PlayerUpdateInput!) {
    updatePlayer(where: { id: $id }, data: $data) {
      id
      img {
        url
      }
      pin
    }
  }
`;

export const uploadPhoto = ({ id, image }) =>
  gqlClient
    .request(query, { id, data: { img: { upload: image } } })
    .then(({ updatePlayer }) => updatePlayer);
