import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  query getPlayer($pin: String!) {
    player(where: { pin: $pin }) {
      name
      email
      id
      score
      pin
      img {
        url
      }
      team {
        id
        name
        synchronized_at
        score
      }
    }
  }
`;

export const playerByPin = pin =>
  gqlClient
    .request(query, { pin })
    .then(({ player: playerData }) => playerData);
