import { observable, action } from 'mobx';
import { news } from '../api/news';
import { addNewsLike } from '../api/add-news-like';
import { removeNewsLike } from '../api/remove-news-like';

/**
 * @typedef NewsRecord
 * @property {string} title
 * @property {string} text
 * @property {any} img
 * @property {string} url
 * @property {string} published_at
 */

export class NewsStore {
  initialData = [];

  @observable fetching = false;

  /** @type {NewsRecord[]} */
  @observable data = this.initialData;

  constructor(appStore) {
    this.appStore = appStore;

    this.fetch = this.fetch.bind(this);
  }

  fetch({ silent = false } = {}) {
    if (!silent) this.setFetching(true);
    news()
      .then(data => {
        this.setData(data);
      })
      .finally(() => {
        if (!silent) this.setFetching(false);
      });
  }

  like = async newsId => {
    const post = await addNewsLike({
      newsId,
      playerId: this.appStore.userStore.data.id,
    });
    this.updatePost(post.id, post);
  };

  dislike = async newsId => {
    const post = await removeNewsLike({
      newsId,
      playerId: this.appStore.userStore.data.id,
    });
    this.updatePost(post.id, post);
  };

  @action
  setData(data) {
    this.data = data;
  }

  @action
  updatePost(id, data) {
    this.data = this.data.map(item => (item.id === id ? data : item));
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }
}
