import React from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

import styles from './errors.module.css';
import { useErrorStore } from '../../store/helpers/hooks';

const DELAY = 7000;

export const Errors = observer(() => {
  const { data, removeError } = useErrorStore();
  return (
    <div className={styles.container}>
      {data.map(({ title, text, id }, i) => (
        <Toast
          // eslint-disable-next-line react/no-array-index-key
          key={title + i}
          onClose={() => removeError(id)}
          show
          delay={DELAY}
          autohide
          className="bg-danger"
        >
          <Toast.Header>
            <strong className={styles.title}>{title}</strong>
          </Toast.Header>
          <Toast.Body className={styles.text}>{text}</Toast.Body>
        </Toast>
      ))}
    </div>
  );
});

Errors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ),
};
