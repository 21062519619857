import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import styles from './confirm-modal.module.css';
import { Modal } from '../modal/modal';
import { CheckIcon } from '../../icons/check';
import { CrossIcon } from '../../icons/cross';

export const ConfirmModal = ({
  title,
  text,
  onConfirm,
  onDecline,
  confirmBtn = 'Готов',
  declineBtn = 'Не готов',
  show,
  onHide,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Subtitle>{text}</Modal.Subtitle>
      <Modal.Actions>
        <Button variant="success" onClick={onConfirm}>
          <span className={styles.icon}>
            <CheckIcon />
          </span>
          {confirmBtn}
        </Button>
        {onDecline && (
          <Button variant="danger" onClick={onDecline}>
            <span className={styles.icon}>
              <CrossIcon />
            </span>
            {declineBtn}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onDecline: PropTypes.func,
  confirmBtn: PropTypes.string,
  declineBtn: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
