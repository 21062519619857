import { observable, action, computed } from 'mobx';
import { hashSync, compareSync } from 'bcryptjs';

import { loadPlayer } from '../api/load-player';
import { playerByPin } from '../api/player-by-pin';
import { player } from '../api/player';
import { playersScore } from '../api/playersScore';
import { registerPlayer } from '../api/register-player';
import { teamsScore } from '../api/teamsScore';
import { uploadPhoto } from '../api/upload-photo';
import { getStorageItem, setStorageItem } from '../helpers';
import { createPlayer } from '../api/create-player';

const pincodeStorageKey = 'quiz-sinergy-user-pincode';
const credentialsStorageKey = 'quiz-sinergy-user-credentials';
const credentialsStorageSeparator = '|';

/**
 * @typedef User
 * @property {number} id
 * @property {string} name
 * @property {string} email
 * @property {string} team
 * @property {any} img
 */

export class UserStore {
  initialData = {};

  @observable fetching = false;

  /** @type {User} */
  @observable data = this.initialData;

  @observable playersScore = [];

  @observable teamsScore = [];

  constructor(appStore) {
    this.appStore = appStore;

    this.signIn = this.signIn.bind(this);
    this.fetchUserDataByPin = this.fetchUserDataByPin.bind(this);
    this.fetchUserData = this.fetchUserData.bind(this);
  }

  @computed
  get isAuthorized() {
    return Boolean(this.data && this.data.name);
  }

  @computed
  get teamId() {
    return this.data && this.data.team && this.data.team.id;
  }

  @computed
  get userId() {
    return this.data && this.data.id;
  }

  @computed
  get hasTeam() {
    return this.data.team && this.data.team.id;
  }

  loadUserData = async () => {
    this.setFetching(true);
    await this.fetchUserDataByPin(this.pincode);
    this.setFetching(false);
  };

  loadUserDataById = async () => {
    const data = await loadPlayer(this.data.id);
    this.setData(data);
  };

  fetchUserDataByPin(pin) {
    this.setFetching(true);
    return playerByPin(pin)
      .then(data => {
        const result = data || {};
        this.setData(result);
        return result;
      })
      .catch(() => {
        throw new Error(
          'Не удалось получить данные пользователя, возможно он не существует.'
        );
      })
      .finally(() => {
        this.setFetching(false);
      });
  }

  fetchUserData(email, password, encryptedPassword) {
    this.setFetching(true);
    return player(email)
      .then(data => {
        const result = data || {};
        const isPassAccepted =
          encryptedPassword === result.password ||
          compareSync(password, result.password);

        if (!isPassAccepted) {
          throw new Error('Пароль неверный!');
        }

        this.setData(result);
        return result;
      })
      .catch(() => {
        throw new Error(
          'Не удалось получить данные пользователя, возможно он не существует.'
        );
      })
      .finally(() => {
        this.setFetching(false);
      });
  }

  fetchPlayersScore() {
    this.setFetching(true);
    return playersScore()
      .then(data => {
        this.setPlayersScore(data);
      })
      .finally(() => {
        this.setFetching(false);
      });
  }

  fetchTeamsScore() {
    this.setFetching(true);
    return teamsScore()
      .then(data => {
        this.setTeamsScore(data);
      })
      .finally(() => {
        this.setFetching(false);
      });
  }

  registerPlayerByPincode = async ({ pincode, name }) => {
    this.setFetching(true);
    try {
      if (!this.data.id) {
        await this.fetchUserDataByPin(pincode);
      }
      const data = await registerPlayer({ id: this.data.id, name });
      this.setData(data);
    } catch (error) {
      this.appStore.publishError({
        text: 'Не удалось зарегистрировать пользователя.',
      });
    } finally {
      this.setFetching(false);
    }
  };

  registerPlayer = async ({ email, name, password }) => {
    this.setFetching(true);
    try {
      const encryptedPass = hashSync(password);
      const data = await createPlayer({
        email,
        name,
        password: encryptedPass,
      });
      this.setData(data);
      this.appStore.cleanErrors();
    } catch (error) {
      this.appStore.publishError({
        text: 'Не удалось зарегистрировать пользователя.',
      });
    } finally {
      this.setFetching(false);
    }
  };

  async checkSession() {
    const pincode = getStorageItem(pincodeStorageKey);
    const credentialsStorage = getStorageItem(credentialsStorageKey);

    if (pincode || credentialsStorage) {
      try {
        const [email, encryptedPassword] = credentialsStorage.split(
          credentialsStorageSeparator
        );
        // const password = decodeBase64(encryptedPassword);
        const password = encryptedPassword;
        await this.signIn({
          pincode,
          email,
          password,
        });
      } catch (error) {
        await this.signOut();
      }
    }
  }

  async signInByPincode(pincode) {
    const response = await this.fetchUserDataByPin(pincode);

    if (!response.name) {
      throw new Error('Пользователь не зарегистрирован.');
    }
    setStorageItem(pincodeStorageKey, pincode);
    return null;
  }

  async signIn({ email, password, encryptedPassword, pincode }) {
    // TODO: check pincode flow
    if (pincode) {
      return this.signInByPincode(pincode);
    }

    const response = await this.fetchUserData(
      email,
      password,
      encryptedPassword
    );

    if (!response.name) {
      throw new Error('Пользователь не зарегистрирован.');
    }

    // const base64Pass = encodeBase64(password);
    const base64Pass = password;

    setStorageItem(
      credentialsStorageKey,
      `${email}${credentialsStorageSeparator}${base64Pass}`
    );
    return null;
  }

  signOut() {
    setStorageItem(pincodeStorageKey, '');
    setStorageItem(credentialsStorageKey, '');
    this.setData(this.initialData);
  }

  async uploadAvatar(file) {
    const data = await uploadPhoto({ id: this.data.id, image: file });
    this.setData({ ...this.data, ...data });
  }

  @action
  setData(data) {
    this.data = data;
  }

  @action
  setPlayersScore(data) {
    this.playersScore = data
      .sort((a, b) => b.score - a.score)
      .filter(item => Boolean(item.is_active));
  }

  @action
  setTeamsScore(data) {
    this.teamsScore = data.sort((a, b) => b.score - a.score);
  }

  @action
  setAvatar(data) {
    this.data.img = data;
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }

  @action.bound
  setPincode(value) {
    this.pincode = value;
  }
}
