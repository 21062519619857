import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import styles from './task-options.module.css';
import { AnswerCountdown } from '../answer-countdown/answer-countdown';

// const alphabet = 'ABCDEFGHIJKLMN';

function TaskOptions({ options, onSubmit, multiple, timer, timerStartTime }) {
  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={async (values, formikHelpers) => {
        await onSubmit(
          multiple
            ? Object.keys(values)
                .filter(key => values[key])
                .join('|')
            : values.radio
        );
        formikHelpers.setSubmitting(false);
      }}
    >
      {({ handleSubmit, handleChange, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <div className={styles.title}>Варианты ответа:</div>
            <div className={styles.options}>
              {options.map(({ text, id }, i) => (
                <Form.Group key={text} className={styles.item}>
                  <Form.Check
                    custom
                    type={multiple ? undefined : 'radio'}
                    label={text}
                    name={multiple ? text : 'radio'}
                    value={multiple ? true : text}
                    id={id + i}
                    onChange={handleChange}
                  />
                </Form.Group>
              ))}
            </div>
          </div>
          <div className={styles.submit}>
            <Button
              className={styles.btn}
              variant="success"
              type="submit"
              disabled={isSubmitting}
            >
              Ответить
            </Button>
            {timer && (
              <AnswerCountdown
                timeoutInSeconds={timer}
                startTime={timerStartTime}
                onComplete={() => onSubmit('')}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

TaskOptions.propTypes = {
  options: PropTypes.arrayOf({
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  multiple: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  timer: PropTypes.number,
  timerStartTime: PropTypes.string.isRequired,
};

export default TaskOptions;
