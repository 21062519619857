import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import cn from 'classnames';

import commonStyles from '../../styles/common.module.css';
import styles from './team-rating.module.css';
import { useAppStore } from '../../store/app';
import RatingItem from '../../components/rating/rating-item';
import { Spinner } from '../../components/spinner/spinner';

const TeamRating = observer(() => {
  const { userStore } = useAppStore();
  const { teamsScore, fetching, hasTeam } = userStore;
  useEffect(() => {
    if (hasTeam) {
      userStore.fetchTeamsScore();
    }
  }, [hasTeam]);
  return (
    <>
      {fetching && <Spinner />}
      <div className={cn(commonStyles.card, styles.top)}>
        <h1 className={cn(commonStyles.titleH2, styles.title)}>
          Рейтинг полиса
        </h1>
        <div className={styles.list}>
          {teamsScore.length > 0 &&
            teamsScore.map((item, index) => {
              const position = index + 1;
              return (
                <RatingItem
                  key={position + item.name}
                  position={position}
                  name={item.name}
                  score={item.score}
                  isHighlighted={item.id === userStore.data.team.id}
                  isTeamRating
                />
              );
            })}
        </div>
      </div>
    </>
  );
});

export default TeamRating;
