import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from 'react-bootstrap';
import Countdown, { zeroPad } from 'react-countdown';
import { parseISO } from 'date-fns';
import styles from './synchronization.module.css';
import commonStyles from '../../styles/common.module.css';
import { HOUR, MINUTE } from '../../constants';
import { SyncIcon } from '../../icons/sync';

// eslint-disable-next-line no-unused-vars
function Synchronization({
  synchronized_at,
  sync,
  users,
  isUserSynchronized,
  isTeamSynchronized,
}) {
  const hasUsers = Boolean(users && users.length);
  const delay = isTeamSynchronized ? HOUR : MINUTE;
  return (
    <div className={cn(commonStyles.card, styles.container)}>
      {isUserSynchronized ? (
        <div className={styles.title}>Идёт синхронизация</div>
      ) : (
        <p>
          Для входа в&nbsp;командный режим необходимо синхронизироваться
          с&nbsp;вашей командой (необходимо участие в&nbsp;синхронизации
          не&nbsp;менее 3-х участников).
        </p>
      )}
      {synchronized_at && hasUsers && (
        <div className={styles.timer}>
          <div className={styles.counter}>
            <Countdown
              date={parseISO(synchronized_at).getTime() + delay}
              renderer={({ minutes, seconds }) => {
                return hasUsers ? `${minutes}:${zeroPad(seconds)}` : seconds;
              }}
            />
          </div>
        </div>
      )}
      {users && users.length > 0 && (
        <>
          <div className={styles.syncTitle}>Синхронизировались</div>
          <ul className={styles.userList}>
            {users.map(({ name, img }) => (
              <li key={name}>
                <div
                  className={styles.avatar}
                  style={
                    img ? { backgroundImage: `url(${img.url})` } : undefined
                  }
                  title={name}
                />
              </li>
            ))}
          </ul>
        </>
      )}
      {!isUserSynchronized && (
        <div className={styles.syncButton}>
          <Button onClick={sync} variant="success">
            <span className={styles.button}>
              <span className={styles.icon}>
                <SyncIcon />
              </span>
              Синхронизация с командой
            </span>
          </Button>
        </div>
      )}
    </div>
  );
}

Synchronization.propTypes = {
  synchronized_at: PropTypes.string,
  sync: PropTypes.func,
  users: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
  }),
  isUserSynchronized: PropTypes.bool,
  isTeamSynchronized: PropTypes.bool,
};

export default Synchronization;
