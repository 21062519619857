import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  {
    fAQArticles {
      id
      title
      text
      created_at
    }
  }
`;

export const faq = () =>
  gqlClient.request(query).then(({ fAQArticles }) => fAQArticles);
