import React from 'react';
import PropTypes from 'prop-types';

export const HeartIcon = ({ isFilled, color = '#E32D21' }) => (
  <i>
    <svg
      width="31"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3549 3.9313c.5729-.63273 1.0859-1.31676 1.7187-1.84688C19.4933.0237857 22.2807-.531987 25.2648.519706 28.2147 1.5543 30.0615 3.69189 30.6515 6.77856c.5472 2.85582-.2479 5.37814-2.3257 7.40464-3.2577 3.1807-6.5837 6.3016-9.8756 9.4481-.7183.684-1.4365 1.3681-2.1547 2.0521-.5729.5472-1.2313.5301-1.8041-.0171-3.48-3.3261-6.96002-6.6351-10.43146-9.9612-.82939-.7952-1.71007-1.5732-2.40265-2.4796C-1.95951 8.50573.656898 1.49445 6.47114.228994 9.93403-.523436 13.32.86172 15.278 3.82014c.0342.0513.0684.1026.0769.11116z"
        fill={isFilled ? color : '#e8e8e8'}
      />
    </svg>
  </i>
);

HeartIcon.propTypes = {
  isFilled: PropTypes.bool,
  color: PropTypes.string,
};
