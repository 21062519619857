export const PATH = {
  AUTH: '/auth',
  REGISTRATION: '/registration',
  AVATAR_UPLOADER: '/avatar-uploader',
  RESTORE: '/restore',
  NEWS: '/news',
  TASKS: '/tasks',
  ACTIVE_TASKS: '/tasks/active',
  CLOSED_TASKS: '/tasks/closed',
  TEAM_MODE: '/tasks/team',
  RATINGS_PERSONAL: '/ratings-personal',
  RATINGS_TEAM: '/ratings-team',
  PROFILE: '/profile',
  FAQ: '/faq',
  LIVE: '/live',
};

export const HOMEPAGE_PATH = PATH.NEWS;
