import { observable, action } from 'mobx';
import { faq } from '../api/faq';

/**
 * @typedef FaqRecord
 * @property {string} title
 * @property {string} text
 */

export class FaqStore {
  initialData = [];

  @observable fetching = false;

  /** @type {FaqRecord[]} */
  @observable data = this.initialData;

  constructor(appStore) {
    this.appStore = appStore;

    this.fetch = this.fetch.bind(this);
  }

  fetch() {
    this.setFetching(true);
    faq()
      .then(data => {
        this.setData(data);
      })
      .finally(() => {
        this.setFetching(false);
      });
  }

  @action
  setData(data) {
    this.data = data;
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }
}
