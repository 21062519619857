import { differenceInSeconds, parseISO } from 'date-fns';
import { observable, action, computed } from 'mobx';
import { answers } from '../api/answers';
import { createAnswer } from '../api/create-answer';
import { tasks } from '../api/tasks';
import { updateAnswer } from '../api/update-answer';

/**
 * @typedef {Object} Task
 * @property {string} id
 * @property {string} title
 * @property {string} text
 * @property {string} expired_at
 * @property {number} cost
 * @property {Boolean} is_active
 * @property {string} options
 */

export class TasksStore {
  initialData = [];

  @observable fetching = false;

  /** @type {Task[]} */
  @observable data = this.initialData;

  constructor(appStore) {
    this.appStore = appStore;

    this.fetch = this.fetch.bind(this);
    this.createAnswer = this.createAnswer.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
  }

  @computed
  get tasks() {
    return this.data.map(({ options, id, ...rest }) => ({
      ...rest,
      id,
      options: options
        ? options.split('|').map((value, index) => ({
            text: value.trim(),
            id: `${id}-${index}`,
          }))
        : options,
    }));
  }

  @computed
  get activeTasks() {
    return this.tasks.filter(({ is_active, players, answer, timer }) => {
      const isPlayerAnswered = players.find(
        item => item.id === this.appStore.userStore.userId
      );
      const isEmptyAnswer =
        answer &&
        !answer.value &&
        differenceInSeconds(new Date(), parseISO(answer.created_at)) < timer;
      return Boolean(is_active) && (!isPlayerAnswered || isEmptyAnswer);
    });
  }

  @computed
  get closedTasks() {
    return this.tasks.filter(({ is_expired, players, answer, timer }) => {
      const isPlayerAnswered = players.find(
        item => item.id === this.appStore.userStore.userId
      );
      const isEmptyAnswer =
        answer &&
        !answer.value &&
        differenceInSeconds(new Date(), parseISO(answer.created_at)) < timer;
      return Boolean(is_expired) || (isPlayerAnswered && !isEmptyAnswer);
    });
  }

  @computed
  get teamTasks() {
    return this.activeTasks.filter(({ is_team }) => Boolean(is_team));
  }

  @computed
  get commonTasks() {
    return this.activeTasks.filter(({ is_team }) => !is_team);
  }

  @computed
  get hasTeamTasks() {
    return (
      this.tasks.filter(
        ({ is_active, is_team, players }) =>
          is_active &&
          is_team &&
          !players.find(item => item.id === this.appStore.userStore.userId)
      ).length > 0
    );
  }

  async fetch() {
    try {
      this.setFetching(true);
      const [tasksData, answersData] = await Promise.all([
        tasks(),
        answers(this.appStore.userStore.userId),
      ]);
      const taskWithAnswers = tasksData.map(taskItem => {
        const answer = answersData.find(
          answerItem => answerItem.task.id === taskItem.id
        );
        return {
          ...taskItem,
          answer,
        };
      });
      this.setData(taskWithAnswers);
    } catch (error) {
      this.appStore.publishError({
        text: 'Не удалось получить задания, попробуйте обновить страницу.',
      });
    } finally {
      this.setFetching(false);
    }
  }

  async createAnswer({ value, task }) {
    try {
      this.setFetching(true);
      const { score, created_at } = await createAnswer({
        value,
        task,
        player: this.appStore.userStore.userId,
      });

      const isSucceded = score > 0;
      const taskData = this.data.find(item => item.id === task);
      return {
        score,
        text: isSucceded ? taskData.success_text : taskData.fail_text,
        created_at,
      };
    } catch (error) {
      this.appStore.publishError({
        text: 'Не удалось отправить ответ, попробуйте ещё раз.',
      });
    } finally {
      this.setFetching(false);
    }
    return {};
  }

  async updateAnswer({ value, answerId }) {
    try {
      this.setFetching(true);
      const { score, created_at, task } = await updateAnswer({
        value,
        id: answerId,
      });

      const isSucceded = score > 0;
      const taskData = this.data.find(item => item.id === task.id);
      return {
        score,
        text: isSucceded ? taskData.success_text : taskData.fail_text,
        created_at,
      };
    } catch (error) {
      this.appStore.publishError({
        text: 'Не удалось отправить ответ, попробуйте ещё раз.',
      });
    } finally {
      this.setFetching(false);
    }
    return {};
  }

  @action
  setData(data) {
    this.data = data;
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }
}
