import React from 'react';

export const CrossIcon = () => (
  <i>
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.249 6.74951L6.74951 20.249M6.75 6.74951L20.2495 20.249"
        stroke="#fff"
        strokeWidth="4"
      />
    </svg>
  </i>
);
