import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parseISO, formatDuration, intervalToDuration } from 'date-fns';
import ru from 'date-fns/locale/ru';

export const ExpiredAt = ({ value, label = 'До завершения' }) => {
  // eslint-disable-next-line no-unused-vars
  const [forceUpdate, setForceUpdate] = useState(Date.now());

  useEffect(() => {
    const timer = setInterval(() => {
      setForceUpdate(Date.now());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  const expDate = parseISO(value);
  const now = new Date();
  const interval = intervalToDuration({ start: now, end: expDate });

  return (
    <>
      {label}:{' '}
      {formatDuration(interval, {
        format: ['days', 'hours', 'minutes'],
        locale: ru,
      })}
    </>
  );
};

ExpiredAt.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
};
