import React from 'react';
import PropTypes from 'prop-types';

export const LiveIcon = ({ isActive = false }) => (
  <i>
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={isActive ? 'url(#live-paint0_linear)' : 'none'}
        d="M1 1h21v15H1z"
      />
      <path
        d="M15.0397 7.68132L9.95849 4.90109c-.29217-.15985-.6375-.15389-.92388.0158-.28655.16986-.45746.46992-.45746.80297v5.51274c0 .3314.16986.631.45448.801.14863.0888.3134.1334.47852.1334.15126 0 .30287-.0374.44237-.1125l5.08138-2.73232c.3017-.16231.4899-.47589.4913-.81859.0013-.3427-.1847-.65768-.4855-.82227zM9.92497 10.5391V6.41895l3.79763 2.07797-3.79763 2.04218z"
        fill="#000"
      />
      <path
        d="M22.8307 4.11155l-.0011-.01053c-.0195-.18513-.2134-1.83178-1.0139-2.66932C20.8904.446583 19.8415.326909 19.337.269529c-.0418-.004738-.0801-.009125-.1143-.013687l-.0402-.004211C16.1419.0305326 11.5499.00035095 11.5039.00017547L11.4999 0l-.004.00017547c-.046.00017548-4.638.03035713-7.706.25145553l-.04053.004211c-.03264.004387-.06861.008423-.10774.012985-.4987.057556-1.53646.177405-2.46437 1.198143C.414822 2.29556.194426 3.90694.17179 4.08804l-.002632.02351C.162314 4.18858 0 6.02247 0 7.86355v1.72106c0 1.84109.162314 3.67499.169158 3.75219l.001228.0116c.019478.1821.213202 1.7986 1.010034 2.6365.87.9521 1.97005 1.0781 2.56175 1.1458.09353.0107.17408.0198.229.0295l.05317.0074c1.75562.167 7.26006.2493 7.49346.2527l.007.0001.007-.0001c.046-.0002 4.6378-.0304 7.6785-.2515l.0402-.0042c.0384-.0051.0816-.0097.1289-.0146.4959-.0526 1.5281-.1619 2.4432-1.1688.7624-.8288.983-2.4402 1.0054-2.6211l.0027-.0235c.0068-.0772.1693-1.9109.1693-3.75199V7.86355c-.0002-1.84108-.1625-3.67479-.1693-3.752zM21.652 9.58461c0 1.70399-.1488 3.45899-.1628 3.61999-.0572.4438-.2897 1.4633-.6612 1.8671-.5728.6301-1.1611.6926-1.5907.738-.0519.0055-.1.0108-.1435.0162-2.941.2127-7.3596.2453-7.5632.2465-.2283-.0033-5.6522-.0863-7.3543-.2444-.08721-.0142-.18144-.0251-.28076-.0363-.50379-.0578-1.1934-.1367-1.72369-.72l-.01246-.0133c-.36499-.3803-.59082-1.3336-.6482-1.8485-.01071-.1218-.16337-1.8974-.16337-3.62529V7.86355c0-1.70211.14845-3.4551.16284-3.61952.06826-.52274.30515-1.48048.66119-1.86758.5903-.64925 1.21271-.7212 1.62437-.76875.03931-.00456.07598-.00877.10985-.01316 2.98377-.21373 7.43413-.24549 7.59383-.24672.1597.00105 4.6085.03299 7.5658.24672.0363.00456.076.00912.1186.01404.4234.04825 1.0634.12125 1.6507.74769l.0054.0058c.365.38025.5909 1.35027.6482 1.87547.0102.11494.1634 1.89442.1634 3.62601v1.72106z"
        fill="#000"
      />
      <defs>
        <linearGradient
          id="live-paint0_linear"
          x1="11.4996"
          y1="16.0001"
          x2="11.4996"
          y2="1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE6C0" />
          <stop offset="1" stopColor="#FF8CA7" />
        </linearGradient>
      </defs>
    </svg>
  </i>
);

LiveIcon.propTypes = {
  isActive: PropTypes.bool,
};
