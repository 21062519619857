/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ru from 'date-fns/locale/ru';
import { format, parseISO } from 'date-fns';

import classes from './news.module.css';
import NewsItem from '../../components/news-item/news-item';
import { useNewsStore } from '../../store/helpers/hooks';
import { Spinner } from '../../components/spinner/spinner';

function News() {
  const { data, fetch, fetching } = useNewsStore();

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={classes.container}>
      {fetching && <Spinner />}
      {data
        .filter(item => Boolean(item.is_active))
        .map(({ published_at, img, ...props }) => {
          const date = published_at
            ? format(parseISO(published_at), 'dd MMMM yyyy', {
                locale: ru,
              })
            : null;
          return (
            <div key={props.id} className={classes.item}>
              <NewsItem {...props} img={img.url} date={date} />
            </div>
          );
        })}
    </div>
  );
}

export default observer(News);
