import React from 'react';
import { Redirect } from 'react-router-dom';

import restoreForm from './components/restore-form/restore-form';
import activeTasks from './modules/ActiveTasks/active-tasks';
import closedTasks from './modules/ClosedTasks/closed-tasks';
import news from './modules/News/news';
import teamMode from './modules/TeamMode/team-mode';
import Auth from './modules/Auth/auth';
import Registration from './modules/Registration/registration';
import faq from './modules/FAQ/faq';
import Profile from './modules/Profile/profile';
import { HOMEPAGE_PATH, PATH } from './path';
// import authBg from './assets/login-bg.jpg';
import { AvatarUploader } from './modules/AvatarUploader/avatar-uploader';
import { NewsIcon } from './icons/news';
import { ProfileIcon } from './icons/profile';
import { PersonalRatingIcon } from './icons/personal-rating';
import { PolisRatingIcon } from './icons/polis-rating';
import { FaqIcon } from './icons/faq';
import { TasksIcon } from './icons/tasks';
import { LiveIcon } from './icons/live';
import PersonalRating from './modules/PersonalRating/personal-rating';
import TeamRating from './modules/TeamRating/team-rating';

export const ROUTES_CONFIG = [
  {
    path: PATH.LIVE,
    Component: () => null,
    backgroundText: 'эфир',
    layoutColor: 'live',
    title: 'Прямая трансляция',
    isAvailableForNotAuthorized: true,
    showMenu: true,
    Icon: LiveIcon,
    isAvailableForFinalDay: true,
    isAvailableAlways: false,
  },
  {
    path: PATH.AUTH,
    Component: Auth,
    backgroundText: 'вход',
    // backgroundImage: authBg,
    title: 'Добро пожаловать в\u00a0мир LiOn!',
    text: `Приветствуем вас на универсальной платформе для конференций, тимбилдингов и умных ивентов. Погружайтесь в мир современных технологий и общения без границ!`,
    layoutColor: 'blueAuth',
    isAvailableForNotAuthorized: true,
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.REGISTRATION,
    Component: Registration,
    title: 'Регистрация',
    text: `Приветствуем вас на универсальной платформе для конференций, тимбилдингов и умных ивентов. Погружайтесь в мир современных технологий и общения без границ!`,
    backgroundText: 'вход',
    // backgroundImage: authBg,
    layoutColor: 'blueAuth',
    isAvailableForNotAuthorized: true,
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.AVATAR_UPLOADER,
    Component: AvatarUploader,
    // backgroundImage: authBg,
    layoutColor: 'blueAuth',
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.RESTORE,
    Component: restoreForm,
    // backgroundImage: authBg,
    title: 'Забыли пароль/пинкод?',
    text: `Для того, чтобы узнать название своей команды/индивидуальный пароль, пожалуйста, напишите на почту техподдержки`,
    backgroundText: 'вход',
    layoutColor: 'blueAuth',
    isAvailableForNotAuthorized: true,
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.NEWS,
    Component: news,
    title: 'Новости',
    showMenu: true,
    layoutColor: 'orangeNews',
    Icon: NewsIcon,
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.TASKS,
    Component: () => <Redirect to={PATH.ACTIVE_TASKS} />,
    title: 'Задания',
    showMenu: true,
    backgroundText: 'задания',
    layoutColor: 'greenActiveTasks',
    Icon: TasksIcon,
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.ACTIVE_TASKS,
    Component: activeTasks,
    title: 'Активные задания',
    showMenu: true,
    backgroundText: 'задания',
    layoutColor: 'greenActiveTasks',
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.TEAM_MODE,
    Component: teamMode,
    title: 'Режим синхронизации',
    titleSync: 'Задания синхронизации',
    showMenu: true,
    backgroundText: 'синхрон',
    layoutColor: 'greenTeamTasks',
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.CLOSED_TASKS,
    Component: closedTasks,
    title: 'Завершенные задания',
    showMenu: true,
    backgroundText: 'задания',
    layoutColor: 'greenClosedTasks',
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.RATINGS_PERSONAL,
    Component: PersonalRating,
    title: 'Личный рейтинг',
    backgroundText: 'рейтинг',
    showMenu: true,
    layoutColor: 'cyanRating',
    Icon: PersonalRatingIcon,
    isAvailableForFinalDay: false,
    isAvailableAlways: true,
  },
  {
    path: PATH.RATINGS_TEAM,
    Component: TeamRating,
    title: 'Рейтинг Полиса',
    backgroundText: 'рейтинг',
    showMenu: true,
    layoutColor: 'cyanRating',
    Icon: PolisRatingIcon,
    isAvailableForFinalDay: false,
    isAvailableAlways: true,
  },
  {
    path: PATH.FAQ,
    Component: faq,
    title: 'FAQ',
    showMenu: true,
    backgroundText: 'во пр осы',
    layoutColor: 'yellowFaq',
    Icon: FaqIcon,
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: PATH.PROFILE,
    Component: Profile,
    title: 'Профиль',
    showMenu: true,
    layoutColor: 'blueProfile',
    Icon: ProfileIcon,
    isAvailableForFinalDay: true,
    isAvailableAlways: true,
  },
  {
    path: '/',
    Component: () => <Redirect to={HOMEPAGE_PATH} />,
  },
];
