import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { observer } from 'mobx-react-lite';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import styles from './layout.module.css';
import Header from '../header/header';
import { Chat } from '../../modules/Chat/chat';
import { useTeamStore } from '../../store/helpers/hooks';
import { Modal } from '../modal/modal';
import { SYNC_MODALS } from '../../store/team';
import { PATH } from '../../path';

const MODAL_TEXT = {
  [SYNC_MODALS.INVITE]: {
    title:
      'Жители полиса приглашают вас синхронизироваться, чтобы выполнять задания вместе',
  },
  [SYNC_MODALS.SUCCESS]: {
    title: 'Время синхронизации подошло к концу',
  },
  [SYNC_MODALS.NOT_ENOUGH]: {
    title: 'Недостаточно участников',
    description: 'Договоритесь с командой, чтобы приступить к общим заданиям',
  },
};

function Layout({
  children,
  isAuthorization,
  showProfile = true,
  showChat = true,
}) {
  const { showSyncModal, setShowSyncModal } = useTeamStore();
  const { pathname } = useLocation();
  const history = useHistory();
  const text = MODAL_TEXT[showSyncModal];
  const isModalOpened = Boolean(showSyncModal);
  return (
    <>
      {!isAuthorization && <Header showProfile={showProfile} />}
      <div
        className={cn(styles.container, !isAuthorization && styles.withHeader)}
      >
        {children}
      </div>
      {showChat && !isAuthorization && (
        <>
          <Chat />
          <Modal show={isModalOpened}>
            {text && (
              <>
                <Modal.Title>{text.title}</Modal.Title>
                {text.description && <p>{text.description}</p>}
                <Button
                  variant="success"
                  onClick={() => {
                    setShowSyncModal(null);
                    if (
                      showSyncModal === SYNC_MODALS.INVITE &&
                      !PATH.TEAM_MODE.includes(pathname)
                    ) {
                      history.push(PATH.TEAM_MODE);
                    }
                  }}
                >
                  Перейти
                </Button>
              </>
            )}
          </Modal>
        </>
      )}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  isAuthorization: PropTypes.bool,
  showProfile: PropTypes.bool,
  showChat: PropTypes.bool,
};

export default observer(Layout);
