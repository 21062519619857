import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form, Button } from 'react-bootstrap';

import Countdown from 'react-countdown';
import styles from './simple-question.module.css';

const defaultInitialValue = {
  text: '',
};

const validate = ({ text }) => {
  const errors = {
    text: text ? undefined : 'Введите текст ответа',
  };
  return JSON.parse(JSON.stringify(errors));
};

const SimpleQuestion = ({ submit, disabled, value, onChangeText, timer }) => {
  return (
    <Formik
      // TODO: fix input text bug
      initialValues={value ? { text: value } : defaultInitialValue}
      enableReinitialize
      onSubmit={async ({ text }, formikHelpers) => {
        await submit(text);
        formikHelpers.setSubmitting(false);
      }}
      validate={validate}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        values,
        status,
        touched,
        isSubmitting,
      }) => {
        const isValid =
          touched.text &&
          values.text &&
          !errors.text &&
          status &&
          status.message;
        const isInvalid = Boolean(errors.text);
        const isFormDisabled = disabled || isSubmitting;
        const placeholder = 'Напишите ответ';
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control
                className={styles.input}
                type="text"
                name="text"
                value={values.text}
                placeholder={placeholder}
                onChange={event => {
                  handleChange(event);
                  if (onChangeText) {
                    onChangeText(event.target.value);
                  }
                }}
                isInvalid={isInvalid}
                isValid={isValid}
                autoComplete="off"
                disabled={isFormDisabled}
              />
              <Form.Control.Feedback type="invalid">
                {errors.text}
              </Form.Control.Feedback>
            </Form.Group>

            <div className={styles.submit}>
              <Button
                className={styles.btn}
                variant="success"
                type="submit"
                disabled={isFormDisabled}
              >
                Ответить
              </Button>
              {timer && (
                <div className={styles.timer}>
                  Чтобы дать ответ, у вас осталось&nbsp;
                  <Countdown
                    date={Date.now() + timer * 1000}
                    renderer={({ minutes, seconds }) => {
                      return `${
                        minutes ? `${minutes} минут ` : ''
                      }${seconds} секунд`;
                    }}
                    onComplete={() => submit('')}
                  />
                </div>
              )}
            </div>
            <Form.Control.Feedback>
              {status ? status.message : null}
            </Form.Control.Feedback>
          </Form>
        );
      }}
    </Formik>
  );
};

SimpleQuestion.propTypes = {
  submit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onChangeText: PropTypes.func,
  value: PropTypes.string,
  timer: PropTypes.number,
};

export default SimpleQuestion;
