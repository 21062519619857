import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  query getAnswers($id: IDFilter) {
    answers(where: { player: { id: $id } }) {
      id
      score
      value
      created_at
      task {
        id
      }
    }
  }
`;

export const answers = id =>
  gqlClient
    .request(query, {
      id: {
        equals: id,
      },
    })
    .then(({ answers: answersData }) => answersData);
