import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  {
    tasks(orderBy: { published_at: desc }) {
      text
      teaser
      title
      id
      img {
        url
      }
      type
      options
      is_team
      is_active
      score
      expired_at
      timer
      success_text
      fail_text
      published_at
      players {
        id
      }
      is_expired
    }
  }
`;

export const tasks = () =>
  gqlClient.request(query).then(({ tasks: tasksData }) => tasksData);
