import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  query getPlayer($id: ID!) {
    player(where: { id: $id }) {
      name
      email
      id
      score
      pin
      img {
        url
      }
      team {
        id
        name
        synchronized_at
        score
      }
    }
  }
`;

export const loadPlayer = id =>
  gqlClient.request(query, { id }).then(({ player }) => player);
