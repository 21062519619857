import React from 'react';

export const ChatIcon = () => (
  <i>
    <svg
      width="32"
      height="26"
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.7538 6.69287h-.8765v9.63343c0 1.4999-.8733 2.8562-2.6241 2.8562H9.10046v.458c0 1.3261 1.50824 2.6646 3.05714 2.6646h13.1141l5.0171 2.9499-.7276-2.9499h.1926c1.5478 0 2.2462-1.3354 2.2462-2.6646V8.9797c0-1.32609-.6984-2.28683-2.2462-2.28683z"
        fill="#fff"
      />
      <path
        d="M23.6927 0H3.77009C2.01724 0 0 1.55717 0 3.05813V15.1324c0 1.3823 1.70914 2.3805 3.34854 2.5346l-1.06691 4.0501 6.83654-4.022H23.6927c1.7528 0 3.3704-1.0628 3.3704-2.5627V3.05813C27.0631 1.55717 25.4445 0 23.6927 0zM6.80949 10.1924c-.99405 0-1.7997-.80567-1.7997-1.79972s.80565-1.7997 1.7997-1.7997c.993 0 1.79969.80565 1.79969 1.7997 0 .99405-.80669 1.79972-1.79969 1.79972zm6.72201 0c-.994 0-1.7997-.80567-1.7997-1.79972s.8057-1.7997 1.7997-1.7997c.9941 0 1.7997.80565 1.7997 1.7997 0 .99405-.8056 1.79972-1.7997 1.79972zm6.7231 0c-.994 0-1.8007-.80567-1.8007-1.79972s.8067-1.7997 1.8007-1.7997c.992 0 1.7997.80565 1.7997 1.7997 0 .99405-.8077 1.79972-1.7997 1.79972z"
        fill="#fff"
      />
    </svg>
  </i>
);
