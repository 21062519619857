import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { ROUTES_CONFIG } from '../../routes-config';
import commonStyles from '../../styles/common.module.css';
import styles from './menu.module.css';
import { useAppStore } from '../../store/app';

export const Menu = observer(() => {
  const appStore = useAppStore();
  const { pathname } = useLocation();
  return (
    <div className={cn(commonStyles.card, styles.card)}>
      <ul className={styles.list}>
        {ROUTES_CONFIG.filter(
          ({ showMenu, isAvailableForFinalDay, isAvailableAlways }) =>
            Boolean(showMenu) &&
            (appStore.isFinalDay ? isAvailableForFinalDay : isAvailableAlways)
        ).map(({ title, path, Icon }) => {
          const isActive = pathname.includes(path);
          return (
            <li key={path}>
              <Link
                className={cn(styles.listLink, { [styles.active]: isActive })}
                to={path}
              >
                <span className={styles.icon}>
                  {Icon && <Icon isActive={isActive} />}
                </span>
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
});
