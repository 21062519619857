import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader/root';
import { configure } from 'mobx';
import queryString from 'query-string';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AppStoreContext, AppStore } from './store/app';
import Routes from './pages/routes';
import { Errors } from './components/errors/errors';

configure({ enforceActions: 'observed' });

const browserHistory = createBrowserHistory();

const parsed = queryString.parse(window.location.search);
const { token } = parsed;

function App() {
  const config = {
    token,
  };
  const store = useMemo(() => new AppStore(config), []);

  return (
    <AppStoreContext.Provider value={store}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
      <Errors />
    </AppStoreContext.Provider>
  );
}

export default hot(App);
