import React from 'react';
import PropTypes from 'prop-types';
import Countdown, { zeroPad } from 'react-countdown';
import { parseISO } from 'date-fns';

import styles from './answer-countdown.module.css';

export const AnswerCountdown = ({
  timeoutInSeconds,
  startTime,
  onComplete,
}) => {
  return (
    <div className={styles.countdown}>
      Чтобы дать ответ, у вас осталось&nbsp;
      {startTime ? (
        <Countdown
          date={parseISO(startTime).getTime() + timeoutInSeconds * 1000}
          renderer={({ minutes, seconds }) => {
            return `${minutes}:${zeroPad(seconds)}`;
          }}
          onComplete={onComplete}
        />
      ) : (
        '...'
      )}
    </div>
  );
};

AnswerCountdown.propTypes = {
  startTime: PropTypes.string.isRequired,
  timeoutInSeconds: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
};
