/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { observer } from 'mobx-react-lite';
// import cn from 'classnames';

import styles from './live.module.css';
import classes from '../../styles/common.module.css';
import { Spinner } from '../../components/spinner/spinner';
import { MegaChat } from '../Chat/mega-chat';
import { useMegaChatStore } from '../../store/helpers/hooks';
import { LiveAuth } from './live-auth';

export const Live = observer(() => {
  const { isAuthorized, fetching } = useMegaChatStore();
  return (
    <div className={classes.container}>
      {fetching && <Spinner />}
      {isAuthorized ? (
        <>
          <div className={styles.live}>
            <iframe
              src="https://facecast.net/v/pkg0ge"
              style={{ width: '100%', height: '100%' }}
              allow="autoplay; fullscreen"
              allowFullScreen
              title="facecast"
            />
          </div>
          <div className={styles.chat}>
            <MegaChat />
          </div>
        </>
      ) : (
        <LiveAuth />
      )}
    </div>
  );
});
