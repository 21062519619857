import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  mutation updatePlayer($id: ID!, $data: PlayerUpdateInput!) {
    updatePlayer(where: { id: $id }, data: $data) {
      name
      email
      id
      img {
        url
      }
      team {
        id
        name
        synchronized_at
        score
      }
    }
  }
`;

export const registerPlayer = ({ id, name }) =>
  gqlClient
    .request(query, {
      id,
      data: {
        name,
      },
    })
    .then(({ updatePlayer }) => updatePlayer);
