import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Separator } from '../separator/separator';
import { PATH } from '../../path';

import styles from '../../styles/common.module.css';
import formStyles from './auth-form.module.css';
import Password from '../password/password';

const initialValues = { email: '' };

function AuthForm({ submit, type }) {
  const isEmailType = type === 'email';

  const validate = ({ email, password, pincode }) => {
    const errors = isEmailType
      ? {
          email: email ? undefined : 'Email обязателен',
          password: password ? undefined : 'Пароль обязателен',
        }
      : {
          pincode: pincode ? undefined : 'Пинкод обязателен',
        };
    return JSON.parse(JSON.stringify(errors));
  };
  const submitForm = async values => {
    await submit(values);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validate={validate}
      >
        {({ handleSubmit, isSubmitting, handleChange, errors, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {isEmailType ? (
                <>
                  <Form.Group>
                    <Form.Control
                      type="email"
                      name="email"
                      id="email"
                      value={values.email}
                      placeholder="E-mail"
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Password
                      type="password"
                      name="password"
                      id="password"
                      value={values.password}
                      placeholder="Пароль"
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="pincode"
                    id="pincode"
                    value={values.pincode}
                    placeholder="Введите пинкод"
                    onChange={handleChange}
                    isInvalid={!!errors.pincode}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pincode}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                block
              >
                Войти
              </Button>
            </Form>
          );
        }}
      </Formik>
      <div className={formStyles.restore}>
        <Link to={PATH.RESTORE}>{`Забыли свой ${
          isEmailType ? 'пароль' : 'пинкод'
        }?`}</Link>
      </div>
      <Separator />
      <Link to={PATH.REGISTRATION} className={styles.buttonLink}>
        <Button variant="secondary" block>
          Регистрация
        </Button>
      </Link>
    </div>
  );
}

AuthForm.propTypes = {
  submit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['email', 'pincode']),
};

AuthForm.defaultProps = {
  type: 'email',
};

export default observer(AuthForm);
