import { observable, action, computed } from 'mobx';
import { messages } from '../api/messages';
import { sendMessage } from '../api/send-message';

export class ChatStore {
  initialData = [];

  @observable fetching = false;

  @observable data = this.initialData;

  @observable isOpened = false;

  constructor(appStore) {
    this.appStore = appStore;

    this.fetchMessages = this.fetchMessages.bind(this);
  }

  @computed
  get messages() {
    return this.data;
  }

  fetchMessages = async () => {
    const data = await messages(this.appStore.userStore.data.team.id);

    if (data) {
      this.setData(data);
    }
  };

  sendMessage = async text => {
    try {
      await sendMessage({
        player: this.appStore.userStore.data.id,
        team: this.appStore.userStore.data.team.id,
        text,
      });
    } catch (error) {
      this.appStore.publishError({
        text: 'Не удалось отправить сообщение, попробуйте ещё раз.',
      });
    }
  };

  @action
  setData(data) {
    this.data = data;
  }

  @action
  setFetching(value) {
    this.fetching = value;
  }

  @action.bound
  setOpened(value) {
    this.isOpened = value;
  }
}
