import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { observer } from 'mobx-react-lite';
import styles from './photo-uploader.module.css';
import { useAppStore } from '../../store/app';
// import commonStyles from '../../styles/common.module.css';
import { Spinner } from '../spinner/spinner';
// import { Avatar } from '../avatar/avatar';

const DefaultButton = () => (
  <div
    className={cn('btn btn-primary btn-block btn-warning', styles.uploadBtn)}
  >
    Загрузить фото
  </div>
);

export const PhotoUploader = observer(
  ({ name, onUpload, ButtonComponent = DefaultButton }) => {
    const [fetching, setFetching] = useState(false);
    const { userStore } = useAppStore();
    const upload = async file => {
      setFetching(true);
      await userStore.uploadAvatar(file);
      setFetching(false);

      if (onUpload) {
        onUpload();
      }
    };
    return (
      <>
        <label className={styles.container} htmlFor={name}>
          <input
            type="file"
            name={name}
            id={name}
            hidden
            accept="image/*"
            onChange={event => {
              upload(event.currentTarget.files[0]);
            }}
          />
          <ButtonComponent />
        </label>
        {/* TODO: change fetching state */}
        {fetching && <Spinner />}
      </>
    );
  }
);

PhotoUploader.propTypes = {
  url: PropTypes.string,
  onUpload: PropTypes.func,
  ButtonComponent: PropTypes.node,
};
