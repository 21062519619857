/* eslint-disable prefer-destructuring */

export const API_URL = process.env.API_URL;
export const GQL_API_URL = process.env.GQL_API_URL;
// export const GQL_API_URL =
//   process.env.NODE_ENV === 'production'
//     ? process.env.GQL_API_URL
//     : 'http://localhost:3000/admin/api';
export const WEBSOCKET_API_URL = process.env.WEBSOCKET_API_URL;
export const CDN_URL = process.env.CDN_URL;

export const CHAT_API_URL = process.env.CHAT_API_URL;
export const WEBSOCKET_CHAT_API_URL = process.env.WEBSOCKET_CHAT_API_URL;

export const TEST_TOKEN = 'test';
export const TEST_TOKEN_GUEST = 'guest_test';

export const API_STATES = {
  ERROR: 'error',
  SUCCESS: 'success',
};

export const APP_STATES = {
  INITIAL: 'initial',
  CHECKED: 'checked',
  SIGNED_IN: 'signed-in',
  UNAUTHORIZED: 'unauthorized',
  ERROR: 'error',
};

export const QUESTION_TYPES = {
  STUB: 0,
  QUESTION: 1,
  QUESTION_OPTIONS: 2,
  QUESTION_OPTIONS_RATE: 3,
  QUESTION_OPTIONS_INTERACTIVE: 4,
};

export const SERVER_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss'; // formatISO9075

export const TASK_TYPE = {
  TEXT: 0,
  RADIO: 1,
  CHECK: 2,
};

export const HOUR = 3600000;
export const MINUTE = 59000;
