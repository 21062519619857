import React from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';

import styles from './team.module.css';
import { pluralScore } from '../../helpers/pluralize';

function Team({ name, score = 0, players }) {
  return (
    <div>
      <div className={styles.polis}>Полис:</div>
      <div className={styles.teamName}>{name}</div>
      <div className={styles.scoreWrapper}>
        <div className={styles.score}>
          <span className={styles.value}>{score}</span> {pluralScore(score)}
        </div>
      </div>
      {players && players.length && (
        <div className={styles.players}>
          {players.map(player => (
            <div key={player.id} className={styles.player}>
              <div
                className={styles.avatar}
                style={
                  player.img
                    ? { backgroundImage: `url(${player.img.url})` }
                    : undefined
                }
              />
              <div>
                <div className={styles.name}>{player.name}</div>
                <div className={styles.score}>
                  <span className={styles.value}>{player.score}</span>&nbsp;
                  {pluralScore(player.score)}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Team.propTypes = {
  players: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      img: PropTypes.string,
      score: PropTypes.string.isRequired,
    }).isRequired
  ),
  name: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};

export default Team;
