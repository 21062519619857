import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  mutation createPlayer($data: PlayerCreateInput!) {
    createPlayer(data: $data) {
      name
      email
      id
      score
      pin
      img {
        url
      }
      team {
        id
        name
        synchronized_at
        score
      }
    }
  }
`;

export const createPlayer = ({ name, email, password }) =>
  gqlClient
    .request(query, {
      data: {
        name,
        email,
        password,
        pin: 'zozo',
      },
    })
    .then(({ createPlayer: createPlayerData }) => createPlayerData);
