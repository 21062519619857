/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
// import cn from 'classnames';

import classes from '../../styles/common.module.css';
import { useFaqStore } from '../../store/helpers/hooks';
import FaqItem from '../../components/faq-item/faq-item';
import { Spinner } from '../../components/spinner/spinner';

function FAQ() {
  const { data, fetch, fetching } = useFaqStore();

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={classes.container}>
      {fetching && <Spinner />}
      {data.map(props => (
        <div key={props.title} className={classes.item}>
          <FaqItem {...props} />
        </div>
      ))}
    </div>
  );
}

export default observer(FAQ);
