import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  query getPlayer($email: String!) {
    player(where: { email: $email }) {
      name
      email
      id
      score
      pin
      img {
        url
      }
      password
      team {
        id
        name
        synchronized_at
        score
      }
    }
  }
`;

export const player = email =>
  gqlClient
    .request(query, { email })
    .then(({ player: playerData }) => playerData);
