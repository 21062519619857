/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
// import cn from 'classnames';
import { Button } from 'react-bootstrap';

import Countdown, { zeroPad } from 'react-countdown';
import parseISO from 'date-fns/parseISO';
import styles from './team-mode.module.css';
import classes from '../../styles/common.module.css';
import Synchronization from '../../components/synchronization/synchronization';
import {
  useTasksStore,
  useTeamStore,
  useUserStore,
} from '../../store/helpers/hooks';
import { Modal } from '../../components/modal/modal';
import { Spinner } from '../../components/spinner/spinner';
import TaskActive from '../../components/task/task-active';
import { HOUR } from '../../constants';
import { TaskEmpty } from '../../components/task/task-empty';
import { ConfirmModal } from '../../components/confirm-modal/confirm-modal';

function TeamMode() {
  const {
    sync,
    data,
    fetchTeam,
    synchronized,
    fetching,
    isUserSynchronized,
    isTeamSynchronized,
    hasSynchronized,
  } = useTeamStore();
  const { loadUserDataById } = useUserStore();
  const { teamTasks } = useTasksStore();
  const [showSyncPrompt, setShowSyncPrompt] = useState(false);
  const close = () => setShowSyncPrompt(false);
  const { fetch, submitAnswer } = useTasksStore();
  const [showErrorModal, setShowErrorModal] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(null);
  const submit = async ({ value, task }) => {
    const { score, text } = await submitAnswer({ value, task });

    if (score > 0) {
      loadUserDataById();
      setShowSuccessModal(text);
    } else {
      setShowErrorModal(
        value === '' ? 'Время, отведенное на выполнение задания, вышло.' : text
      );
    }
  };

  const applySync = async () => {
    await sync();
    close();
    await fetchTeam();
    await fetch();
  };

  const synchronize = () => {
    if (!hasSynchronized) {
      setShowSyncPrompt(true);
    } else {
      applySync();
    }
  };

  useEffect(() => {
    fetchTeam();
    fetch();
  }, []);

  return (
    <div className={classes.container}>
      {isTeamSynchronized && isUserSynchronized ? (
        <>
          <div className={styles.synchronizedNow}>
            <div className={styles.label}>
              Сейчас <br />
              синхронизированы:
            </div>
            <div className={styles.userList}>
              {synchronized.map(({ name, img }) => (
                <li key={name}>
                  <div
                    className={styles.avatar}
                    style={
                      img ? { backgroundImage: `url(${img.url})` } : undefined
                    }
                    title={name}
                  />
                </li>
              ))}
            </div>
            <div className={styles.time}>
              <Countdown
                date={parseISO(data.synchronized_at).getTime() + HOUR}
                renderer={({ minutes, seconds }) => {
                  return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
                }}
              />
            </div>
          </div>
          {teamTasks.length > 0 ? (
            teamTasks.map(props => (
              <div key={props.title} className={classes.item}>
                <TaskActive {...props} onSubmit={submit} />
              </div>
            ))
          ) : (
            <TaskEmpty description="Сейчас у вас нет доступных заданий в режиме синхронизации, попробуйте зайти позже." />
          )}

          <Modal
            show={Boolean(showErrorModal)}
            onHide={() => {
              setShowErrorModal(null);
              fetch();
            }}
            size="sm"
            backdropClassName={styles.error}
          >
            <div className={styles.modalText}>{showErrorModal}</div>
            <div className={styles.btn}>
              <Button
                block
                variant="success"
                onClick={() => {
                  setShowErrorModal(null);
                  fetch();
                }}
              >
                Ок
              </Button>
            </div>
          </Modal>
          <Modal
            show={Boolean(showSuccessModal)}
            onHide={() => {
              setShowSuccessModal(null);
              fetch();
            }}
            size="sm"
            backdropClassName={styles.success}
          >
            <div className={styles.modalText}>{showSuccessModal}</div>
            <div className={styles.btn}>
              <Button
                block
                variant="success"
                onClick={() => {
                  setShowSuccessModal(null);
                  fetch();
                }}
              >
                Ок
              </Button>
            </div>
          </Modal>
        </>
      ) : (
        <>
          {fetching && <Spinner />}
          <Synchronization
            sync={synchronize}
            users={synchronized}
            synchronized_at={data.synchronized_at}
            isUserSynchronized={isUserSynchronized}
            isTeamSynchronized={isTeamSynchronized}
          />
          <ConfirmModal
            show={showSyncPrompt}
            onHide={close}
            title="Вы готовы синхронизироваться с командой?"
            text="Все участники команды получат уведомления."
            onConfirm={applySync}
            onDecline={close}
          />
        </>
      )}
    </div>
  );
}

export default observer(TeamMode);
