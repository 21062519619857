import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  mutation createAnswer($data: AnswerCreateInput!) {
    createAnswer(data: $data) {
      id
      score
      created_at
    }
  }
`;

export const createAnswer = ({ value, player, task }) =>
  gqlClient
    .request(query, {
      data: {
        value,
        player: { connect: { id: player } },
        task: { connect: { id: task } },
      },
    })
    .then(({ createAnswer: createAnswerData }) => createAnswerData);
