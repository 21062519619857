/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import classes from './header.module.css';
import logo from '../../assets/logo-lion.png';
import { HOMEPAGE_PATH, PATH } from '../../path';
import MobileMenu from '../mobile-menu/mobile-menu';
import { ProfileCard } from '../../modules/ProfileCard/profile-card';

function Header({ showProfile = true }) {
  return (
    <div className={classes.header}>
      <Link className={classes.logo} to={HOMEPAGE_PATH}>
        <span className={cn(classes.polus)}>
          <img src={logo} alt="Полюс" />
        </span>
      </Link>
      {showProfile && (
        <div className={classes.profile}>
          <Link className={classes.profileLink} to={PATH.PROFILE}>
            <ProfileCard />
          </Link>
        </div>
      )}

      {showProfile && (
        <div className={classes.mobileMenu}>
          <MobileMenu />
        </div>
      )}
    </div>
  );
}

Header.propTypes = {
  showProfile: PropTypes.bool,
};

export default Header;
