import React from 'react';
import PropTypes from 'prop-types';

export const NewsIcon = ({ isActive = false }) => (
  <i>
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.81454 15.2284l-1.74435 1.7444 1.74435 2.3258 1.74434-.5815 1.74435.5815 5.81447-2.9073h3.4887l2.9073 1.1629.5814-1.7443-6.9774-11.62899-1.7443 1.1629v1.74434L11.629 9.9954l-5.23301 4.0701-.58145 1.1629z"
        fill={isActive ? 'url(#news-icon-paint0_linear)' : 'none'}
      />
      <path
        d="M23.6403 7.53249L21.87 8.55457c-.3259.18815-.4376.6049-.2494.93079.1882.32589.6049.43756.9308.2494l1.7703-1.02207c.3259-.18816.4375-.6049.2494-.93079-.1882-.32589-.6049-.43756-.9308-.24941zM25.0211 11.6867l-1.8616-.4988c-.3634-.0974-.7371.1183-.8345.4818-.0975.3635.1183.7371.4818.8345l1.8616.4988c.3635.0974.7371-.1183.8345-.4818.0974-.3635-.1183-.7371-.4818-.8345zM20.9328 4.60539c-.3634-.09739-.7371.11834-.8345.4818l-.4988 1.86158c-.0974.36351.1183.73712.4818.83452.3635.0974.7371-.11837.8345-.48181l.4988-1.86158c.0974-.3635-.1183-.73711-.4818-.83451zM13.6509 3.85851c-.8864.51174-1.2445 1.5916-.881 2.51674l-.2275.84907c-.3959 1.47778-1.4527 2.83368-2.82698 3.62708l-3.54059 2.0442c-.76929.4442-1.14036 1.3162-.9874 2.1437l-.1015.0586c-1.30152.7514-1.74905 2.4216-.99762 3.7231.75143 1.3015 2.42164 1.7491 3.72316.9976l.1015-.0586c.46799.3994 1.09653.5678 1.70148.456l1.76205 3.0519c.5636.9762 1.8163 1.3118 2.7924.7482.9761-.5635 1.3118-1.8162.7482-2.7923l-1.7035-2.9505.5901-.3407c1.3742-.7934 3.0769-1.0306 4.5547-.6347l.8491.2275c.6194.7774 1.7337 1.0072 2.6201.4954.9761-.5635 1.3117-1.8162.7481-2.7923L16.4433 4.60673c-.5636-.97614-1.8163-1.31179-2.7924-.74822zM7.13008 18.6385c-.65075.3757-1.48586.1519-1.86157-.4989-.37572-.6507-.15196-1.4858.4988-1.8615l1.36277 2.3604zm6.60682 3.2667c.1879.3254.076.7429-.2494.9308-.3254.1878-.7429.0759-.9308-.2494l-1.7035-2.9505 1.1802-.6814 1.7035 2.9505zm-1.2047-4.8121l-2.95044 1.7035c-.32538.1878-.74293.0759-.93079-.2494l-2.04415-3.5406c-.18786-.3254-.07598-.7429.2494-.9308l2.95048-1.7035 2.7255 4.7208zm5.6932-1.2231c-1.4951-.2872-3.0863-.0833-4.5015.5707l-2.7564-4.7743c1.274-.8984 2.2462-2.17453 2.745-3.61294L18.2254 15.87zm3.1702.0399c.1878.3253.0759.7429-.2494.9307h-.0001c-.3253.1879-.7429.076-.9307-.2494L14.0829 5.9695c-.1879-.32538-.076-.74294.2494-.93082.3254-.18783.7429-.07595.9308.24943l6.1325 10.62179z"
        fill="#000"
      />
      <defs>
        <linearGradient
          id="news-icon-paint0_linear"
          x1="13.0817"
          y1="19.299"
          x2="13.0817"
          y2="4.1814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC917" />
          <stop offset="1" stopColor="#F9AA24" />
        </linearGradient>
      </defs>
    </svg>
  </i>
);

NewsIcon.propTypes = {
  isActive: PropTypes.bool,
};
