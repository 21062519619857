import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  mutation updatePost($id: ID!, $data: PostUpdateInput!) {
    updatePost(where: { id: $id }, data: $data) {
      title
      id
      text
      teaser
      likes {
        id
      }
      published_at
      is_active
      img {
        url
      }
    }
  }
`;

export const removeNewsLike = ({ newsId, playerId }) =>
  gqlClient
    .request(query, {
      id: newsId,
      data: {
        likes: {
          disconnect: [
            {
              id: playerId,
            },
          ],
        },
      },
    })
    .then(({ updatePost }) => updatePost);
