import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './task.module.css';
import commonStyles from '../../styles/common.module.css';

export const TaskEmpty = ({ title = 'Нет заданий', description }) => {
  return (
    <div className={cn(commonStyles.card, styles.empty)}>
      <div className={cn(styles.title, commonStyles.titleH2)}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

TaskEmpty.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
