import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  query getPlayers($isHidden: BooleanFilter) {
    players(where: { is_hidden: $isHidden }) {
      id
      name
      score
      team {
        name
      }
      is_active
      is_hidden
    }
  }
`;

export const playersScore = () =>
  gqlClient
    .request(query, {
      isHidden: {
        not: {
          equals: true,
        },
      },
    })
    .then(({ players }) => players.filter(({ team }) => Boolean(team)));
