import React from 'react';
import { observer } from 'mobx-react-lite';
import Layout from '../components/layout/layout';
import RouteLayout from '../components/route-layout/route-layout';
import RouteLayoutAuthorization from '../components/route-layout/route-layout-authorization';
import { Live } from '../modules/Live/live';
import { useMegaChatStore } from '../store/helpers/hooks';
// import bg from '../assets/login-bg.jpg';

export const LivePage = observer(() => {
  const { isAuthorized } = useMegaChatStore();
  const RouteLayoutComponent = isAuthorized
    ? RouteLayout
    : RouteLayoutAuthorization;
  return (
    <Layout
      isAuthorization={!isAuthorized}
      showProfile={false}
      showChat={false}
    >
      <RouteLayoutComponent
        isAuthorized={isAuthorized}
        // backgroundImage={!isAuthorized && bg}
        backgroundText={isAuthorized ? 'Эфир' : 'Вход'}
        layoutColor={isAuthorized ? 'live' : 'blueAuth'}
        title="Трансляция"
        text="Просим Вас ввести своё имя или придумать никнейм, чтобы мы могли получать от вас приветы и поздравления в чат эфира :)"
        hideSidebar
      >
        <Live />
      </RouteLayoutComponent>
    </Layout>
  );
});
