import React from 'react';
import PropTypes from 'prop-types';

export const ProfileIcon = ({ isActive = false }) => (
  <i>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        fill={isActive ? 'url(#profile-icon-paint0_linear)' : 'none'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19c-4.97057 0-9-4.0294-9-9 0-4.97057 4.02943-9 9-9 4.9706 0 9 4.02943 9 9 0 4.9706-4.0294 9-9 9zm4.9856-2.5714c-.0673-.099-.1522-.222-.2203-.318-.1187-.1689-.2374-.3377-.3437-.5049-.0052-.0077-.5923-.7954-2.7073-.8914-.7359 0-1.2707-.552-1.272-1.314L10.4286 13c0-.0579-.0069.0634 0 0v-.4286l.4285-.4285c.3133-.1453.7115-.3896 1.0265-.6275l.0578-.0398c.249-.1582.597-.4912.63-.6185l.0155-.1632C12.7467 9.86971 13 8.37057 13 7.85714c0-2.01857-.9814-3-3-3-2.04643 0-3 .95315-3 3 0 .52715.25329 2.019.41271 2.83546l.015.1508c.027.1342.34243.4517.621.6266l.042.0266c.33772.2361.74143.4993 1.05215.6463l.42857.4285V13c.006.0591 0-.0626 0 0l-.01414.4003c0 .7607-.534 1.3131-1.23558 1.3131h-.00128c-2.124.0862-2.73986.8902-2.74072.8902-.108.1697-.22628.3381-.34585.5074-.06729.096-.15215.2186-.21986.3176 1.37871 1.0705 3.105 1.7143 4.98557 1.7143 1.88053 0 3.60733-.6438 4.98603-1.7143zM10 1.85714C5.503 1.85714 1.85714 5.503 1.85714 10c0 2.3083.96429 4.3873 2.50715 5.8689.16371-.2422.33857-.4839.49285-.726 0 0 .71572-1.1756 3.42857-1.2858.27643 0 .41443-.1808.41443-.4568h.00043C8.70014 13.177 8.70014 13 8.70014 13c0-.0557-.009-.1059-.01157-.1599-.31028-.1465-.67028-.3488-1.08814-.6402 0 0-1.02857-.6326-1.02857-1.3428 0 0-.42857-2.19253-.42857-2.99996C6.14329 5.86686 6.99443 4 10.0004 4c2.9657 0 3.8572 1.86686 3.8572 3.85714 0 .78772-.4286 2.99996-.4286 2.99996 0 .6905-1.0286 1.3428-1.0286 1.3428-.4093.309-.7718.5151-1.0898.6625-.0022.0467-.0099.0896-.0099.1376 0 0-.0004.177-.0004.4003h.0004c-.0008.276.1372.4568.4136.4568 2.7531.1235 3.4286 1.2858 3.4286 1.2858.1542.2421.3287.4838.4924.726 1.5428-1.4816 2.5076-3.5606 2.5076-5.8689 0-4.497-3.6459-8.14286-8.1429-8.14286z"
        fill="#000"
        stroke="#000"
        strokeWidth=".5"
      />
      <defs>
        <linearGradient
          id="profile-icon-paint0_linear"
          x1="9.99995"
          y1="19"
          x2="9.99995"
          y2="1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCFAD" />
          <stop offset="1" stopColor="#FCC917" />
        </linearGradient>
      </defs>
    </svg>
  </i>
);

ProfileIcon.propTypes = {
  isActive: PropTypes.bool,
};
