import React from 'react';
import { Redirect } from 'react-router-dom';
import RegistrationForm from '../../components/registration-form/registration-form';
import { HOMEPAGE_PATH, PATH } from '../../path';

// import classes from './auth.module.css';
import { useAppStore } from '../../store/app';

function Registration() {
  const { userStore, isAuthorized, authorizationType } = useAppStore();
  const submit = async ({ name, email, password, pincode }) => {
    if (pincode) {
      userStore.setPincode(pincode);
      await userStore.registerPlayerByPincode({ pincode, name });
    } else {
      await userStore.registerPlayer({ name, email, password });
    }
  };

  if (isAuthorized && userStore.data.img) {
    return <Redirect to={HOMEPAGE_PATH} />;
  }

  if (isAuthorized) {
    return <Redirect to={PATH.AVATAR_UPLOADER} />;
  }

  return <RegistrationForm type={authorizationType} submit={submit} />;
}

export default Registration;
