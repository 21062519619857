import React from 'react';

export const CheckIcon = () => (
  <i>
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.441 3.33375L6.62298 12.4276c-.36637.3773-.85906.5724-1.33913.5724-.48006 0-.97276-.1951-1.33912-.5724L.559021 8.95397c-.745361-.76758-.745361-2.00351 0-2.75807.745359-.76758 1.945519-.76758 2.678249 0l2.03395 2.09457L12.7627.575682c.7454-.767576 1.9456-.767576 2.6783 0 .7453.767578.7453 2.003498 0 2.758068z"
        fill="#000"
      />
    </svg>
  </i>
);
