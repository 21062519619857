import { gql } from 'graphql-request';
import { gqlClient } from './gql-client';

const query = gql`
  mutation updateAnswer($id: ID!, $data: AnswerUpdateInput!) {
    updateAnswer(where: { id: $id }, data: $data) {
      id
      score
      created_at
      value
      task {
        id
      }
    }
  }
`;

export const updateAnswer = ({ id, value }) =>
  gqlClient
    .request(query, {
      id,
      data: {
        value,
      },
    })
    .then(({ updateAnswer: updateAnswerData }) => updateAnswerData);
