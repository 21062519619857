import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './user-profile.module.css';
// import commonStyles from '../../styles/common.module.css';
import { pluralScore } from '../../helpers/pluralize';
import { PhotoUploader } from '../photo-uploader/photo-uploader';

function UserProfile({ name, email, img, score = 0 }) {
  const Avatar = () => (
    <div className={styles.avatar} style={{ backgroundImage: `url(${img})` }}>
      <span className={cn(styles.label, !img && styles.contrast)}>
        Сменить аватар
      </span>
    </div>
  );
  return (
    <div className={styles.profile}>
      <PhotoUploader ButtonComponent={Avatar} />
      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
        {email && <div className={styles.email}>{email}</div>}
        <div className={styles.score}>
          <span className={styles.label}>Заработано:&nbsp;</span>
          <span className={styles.value}>{score}</span>
          &nbsp;
          {pluralScore(score)}
        </div>
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  score: PropTypes.number,
  img: PropTypes.shape({
    publicUrl: PropTypes.string.isRequired,
  }),
};

export default UserProfile;
